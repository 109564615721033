@import "src/styles/variables.scss";
.sectionCard-container:hover {
    .action-hover {
        bottom: 5%;
        z-index: 9;
        overflow: hidden;
    }
    .consumer-action-hover {
        bottom: 3%;
        z-index: 9;
        overflow: hidden;
    }
}
.newsCard-container {
    // height: 105%;
    height: 100%;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 4px #999;
    margin: 1rem 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    .card-img {
        position: relative;
        height: 170px;
        img {
            display: block;
            max-width: 100%;
            height: auto;
            object-fit: fill;
            margin: auto;
            // width: 100%;
            height: 100%;
            max-height: 200px;
        }
        .img-overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgba(63, 193, 147, 0.5);
            opacity: 0;
            z-index: 9;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
    }
    .card-content {
        padding: 1.25rem;
        width: 100%;
        margin: 0;
        color: $cpa-black;
        h4 {
            font-size: 1.25rem;
            color: $cpa-main;
            margin: 0 0 0.6rem;
            padding: 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        .author-wrapper {
            width: 100%;
            margin: 0;
            padding: 0 0 0.5rem;
            img {
                display: inline-block;
                border-radius: 50%;
                width: 36px;
                height: 36px;
                box-shadow: 0 0 3px 2px #999;
            }
            p {
                font-size: 0.79rem;
                display: inline-block;
                color: $cpa-black;
                margin: 0;
                padding: 0 10px;
                text-overflow: ellipsis;
                white-space: nowrap;
                // overflow: hidden;
            }
        }
        .counter-wrapper {
            display: flex;
            width: 100%;
            margin: 0;
            padding: 0;
            flex-direction: row;
            justify-content: space-between;
            .row {
                margin: 0;
                padding: 0;
                display: block;
                width: 100%;
                button {
                    position: absolute;
                    bottom: 1.25rem;
                    left: 1.25rem;
                    width: auto;
                }
                span {
                    font-size: 0.75rem;
                    padding: 0;
                    display: flex;
                    i[class*="icon"] {
                        color: $cpa-dark-blue;
                        margin-left: 5px;
                        font-size: 0.8rem;
                        vertical-align: middle;
                        .icon-location {
                            display: inline-block;
                            font-size: 1.1rem !important;
                        }
                    }
                }
            }
        }
    }
    .action-hover {
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: unset;
        background-color: #fff;
        z-index: -1;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        h5 {
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.5;
            padding: 1.25rem 1.25rem 0.5rem;
            display: block;
            color: #42c194;
            margin: 0;
        }
        p {
            font-size: 0.8rem;
            padding: 0 1.25rem;
            height: 40px;
            line-height: 1.5;
            overflow: hidden;
            margin: 0 0 1rem;
        }
        .button-wrapper {
            display: flex;
            justify-content: center;
            // margin-bottom: 25px;
            button {
                margin: auto;
                a {
                    color: #42c194;
                }
            }
        }
    }

    .consumer-action-hover {
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: -1;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        h5 {
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.5;
            padding: 1.25rem 1.25rem 0.5rem;
            display: block;
            color: #42c194;
            margin: 0;
        }
        p {
            font-size: 0.8rem;
            padding: 0 1.25rem;
            height: 40px;
            line-height: 1.5;
            overflow: hidden;
            margin: 0 0 1rem;
        }
        .button-wrapper {
            display: flex;
            justify-content: center;
            // margin-bottom: 25px;
            button {
                margin: auto;
                a {
                    color: inherit;
                }
            }
        }
    }
}

.action-hover .btn-outline-success {
    border-color: $cpa-main !important;
    background-color: #fff !important;
    color: $cpa-main !important;
}

.consumer-action-hover .btn-outline-success {
    border-color: $cpa-main !important;
    background-color: #fff !important;
    color: $cpa-main !important;
}
// @media (max-width: 750px) {
//     .newsCard-container {
//         .card-img {
//             img {
//                 width: 100%;
//                 height: 185px;
//             }
//         }
//     }
// }
