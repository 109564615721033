.guideUs-container {
    .cpa-tooltiptext {
        span {
            font-size: 14px;
        }
    }
    .head-weHearYou {
        .title {
            text-align: right;
            font-size: 2.375rem;
            color: #356a8b;
        }
        .review-btn {
            text-align: left;
        }
    }
    .guideUs-intro {
        margin: 35px 0;
        p {
            color: #000;
            // font-size: 1.4375rem;
        }
    }
    .home-section-head {
        color: #356a8b;
    }
    .card-weHearYou {
        padding: 18px 25px;
        color: #000;
        text-align: center;
        font-size: 1.375rem;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0px 3px 6px #0000000f;
        margin-bottom: 20px;
        cursor: pointer;
        &:hover {
            background: #3fbf92;
            h4 {
                color: #fff !important;
            }
        }
    }
    .active-card {
        background: #3fbf92;
        color: #fff;
    }
    .calendar-view {
        margin-top: 52px;
        // min-height: 1024px;
        position: relative;
        .grid-view {
            padding-bottom: 2rem;
            &:after {
                top: 50px;
                content: "";
                position: absolute;
                width: 1px;
                height: calc(96%-100px);
                bottom: 0;
                background: #707070;
                right: 0;
                left: 0;
                margin: auto;
                z-index: 0;
            }
            .month-card {
                margin-top: 35px;
                margin-bottom: 20px;
                position: relative;
                z-index: 1;
                button {
                    font-size: 1rem;
                }
                .day-card {
                    margin-top: 15px;
                    p {
                        margin: 0;
                    }
                    .col-6 {
                        padding: 0 20px;
                    }

                    .text-card {
                        color: #fff;
                        background: #3fbf92;
                        text-align: right;
                        font-size: 0.9rem;
                        padding: 5px 15px;
                        border-radius: 5px;
                        &:before {
                            content: "";
                            position: absolute;
                            left: 11px;
                            top: 8px;
                            width: 0;
                            height: 0;
                            border-top: 10px solid transparent;
                            border-bottom: 10px solid transparent;
                            border-right: 10px solid #3fbf92;
                        }
                        &.link {
                            &:hover {
                                background-color: #356a8b;
                                &:before {
                                    border-top: 10px solid transparent;
                                    border-bottom: 10px solid transparent;
                                    border-right: 10px solid #356a8b;
                                }
                            }
                        }
                        a {
                            color: #fff !important;
                        }
                    }
                    .date-card {
                        font-size: 0.9rem;
                        color: #000;
                        padding: 5px 15px;
                        border-radius: 5px;
                        &:before {
                            content: "";
                            position: absolute;
                            top: 10px;
                            right: -8px;
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            background: #707070;
                        }
                    }
                    &:nth-child(even) {
                        .col-6:first-of-type {
                            order: 13;
                            .text-card {
                                text-align: right;
                                &:before {
                                    // left: unset;
                                    right: 11px;
                                    border-left: 10px solid #3fbf92;
                                    border-right: unset;
                                }
                                &.link {
                                    &:hover {
                                        &:before {
                                            border-left: 10px solid #356a8b;
                                        }
                                    }
                                }
                            }
                        }
                        .col-6:last-of-type {
                            order: 1;
                            .date-card {
                                text-align: left;
                                &:before {
                                    left: -8px;
                                    right: unset;
                                    background: #707070;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .secendary-cpa-btn {
        background: #356a8b !important;
        color: #fff !important;
    }
    .select-a-complain {
        float: left;
    }
    .home-section {
        h2 {
            color: #356a8b;
            font-size: 2.375rem;
        }
        p {
            font-size: 1.25rem;
            color: #000;
            margin-bottom: 65px;
        }
        .complains-card {
            margin: 12.5px auto;
            border-bottom: 0.75px solid #7070701f;
            .complain-title {
                border-radius: 5px;
                background: #fff;
                color: #356a8b;
                padding: 18px;
                text-align: center;
                box-shadow: 0 0 3px #00000027;
                margin-bottom: 15px;
            }
            .complain-row {
                h5 {
                    font-size: 1.125rem;
                    color: #000;
                }
                .complains-progress-bar {
                    height: 20px;
                    border-radius: 5px;
                    background: #3fbf92;
                    margin-top: 4px;
                }
                span {
                    font-size: 1rem;
                    color: #000;
                }
            }
        }
        .complains-no {
            text-align: left;
            padding: 5px;
            border-bottom: 2px solid #707070;
            span {
                font-size: 1.25rem;
            }
        }
    }
    .working-on-btn {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
    }
    .get-recived-row {
        margin-top: 25px;
    }
}
@media (max-width: 425px) {
    .guideUs-container {
        .text-card {
            font-size: 0.875rem !important;
            padding: 5px 5px !important;
            &.link {
                text-decoration: underline;
            }
        }
    }
}
