@import 'src/styles/variables.scss';
.complain-container{
    position: relative;
    .signin-button{
        position: absolute;
        top: -70px;
        left:45px;
    }
    .form-check{
        input{
            position: relative;
            top: 2px;
            
        }
        label{
            margin-right: 30px;
            margin-left: 30px;
        }
    }
    .complain-btn-contain{
        position: absolute;
        bottom: 75px;
        z-index: 0;
        height: 0;
        transition: 0.75s all ease-in-out;
    }
    .old-complain{
        height: 0;
        overflow: hidden;
    }
    .another-case-content{
        text-align: center;
        font-size: 1.25rem;
    }
    .form-group{
      .form-input{
        margin-bottom: 0 !important;
        span{
          color: black;
          font-size: 12px;
          margin: 0;
          padding: 0;
          display: block;
          text-align: right;
        }
      }
    }
    .image-preview{
        margin: 20px 0;
        width: 150px;
    }
    .react-date-picker{
        width: 100%;
        .react-date-picker__wrapper{
            width: 100%;
            padding: 5px;
            border-radius: 10px;
            border: 1px solid #eaeaea;
        }
        .react-date-picker__inputGroup{
            input{
                border:none;
            }
        }
    }
    
}

.attachedFilesPreview{
    .row{
        width: 100%
    }
}

.attachedFile{
    display: flex;
    background: #42c194;
    padding: 5px;
    border: 1px solid #42c194;
    border-radius: 10px;
    margin: 0 7.5px;
    width: max-content;
}

.deleteFileBtn{
    margin-left: 10px !important;
    color: white !important;
    font-size: 1.5rem !important;
    position: relative !important;
    top: -1px !important;
}

.btnFileName{
    color: white !important;
    font-size: 1rem !important;
}

h4{
    color: #215C80 !important;
    font-size: 1.125rem !important;
}


#customIcon{
    color: #215C80 !important;
    padding: 2px 0px 0px 20px;
}

@media(max-width:425px){
    #radio-answers{
        right: 10rem !important;
    }
}

@media(max-width:426px){
    .cpa-body[dir="ltr"]{
        #radio-answers{
            right: 32rem !important;
        }
    }
}
