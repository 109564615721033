@import 'src/styles/variables.scss';
.specialization-container{
    .specialization-row{
        display: flex;
        padding: 70px 0;
        margin-top: 50px;
        margin-bottom: 112px;
        p{
            color:$cpa-gray;
        }
        &:not(:first-of-type){
            margin-bottom: 86px;
        }
        .img-card{
            margin-left:5%;
        }
        .icon-card{
            text-align: right;
            margin-top: 50px;
            width: 50%;
            .card-icon{
               font-size: 3.375rem;
            }
            .card-head{
                font-size:1.5rem;
                margin-top: 18px;
                h3{
                    color:$cpa-dark-blue;
                }
            }
            .card-desc{
                font-size:  1rem;
                margin-top: 32px;
            }
        }
    }
    .specialization-content{
        margin: 100px auto;
        .spec-card{
            padding: 35px 63px;
            &:hover{
                background-color: #fff;
                box-shadow: 0 8px 9px $cpa-gray;
                border-radius: 5px;
            }
            .icon-card{
                text-align: center;
                .card-icon{
                    font-size: 2.375rem;
                    margin-bottom: 10px;

                }
                .card-head{
                    h3{
                        font-size: 1.25rem;
                        color:$cpa-dark-blue;
                    }
                }
                .card-desc{
                    height: auto !important;
                    color:$cpa-defualt;
                }
            }
        }
    }
}

// .spec-card:first-of-type, .spec-card:nth-of-type(2) {
//     border-bottom: 1px solid rgba($color: $cpa-gray, $alpha: 0.2);
//     border-left: 1px solid rgba($color: $cpa-gray, $alpha: 0.2);
// }
// .spec-card:nth-of-type(3){
//     border-bottom: 1px solid rgba($color: $cpa-gray, $alpha: 0.2);
// }
// .spec-card:nth-of-type(4) , .spec-card:nth-of-type(5){
//     border-left: 1px solid rgba($color: $cpa-gray, $alpha: 0.2);
// }

.spec-card:nth-of-type(3n){
    border-bottom: 1px solid rgba($color: $cpa-gray, $alpha: 0.2);
}

.spec-card:nth-of-type(3n+1){
    border-bottom: 1px solid rgba($color: $cpa-gray, $alpha: 0.2);
    border-left: 1px solid rgba($color: $cpa-gray, $alpha: 0.2);
}

.spec-card:nth-of-type(3n+2){
    border-bottom: 1px solid rgba($color: $cpa-gray, $alpha: 0.2);
    border-left: 1px solid rgba($color: $cpa-gray, $alpha: 0.2);
}

.spec-card:nth-last-of-type(3){
    border-bottom: none;
}

.spec-card:nth-last-of-type(2){
    border-bottom: none;
}

.spec-card:last-of-type{
    border-bottom: none;
}

.b-l{
    border-left: 1px solid rgba($color: $cpa-gray, $alpha: 0.2);
}
.b-b{
    border-bottom: 1px solid rgba($color: $cpa-gray, $alpha: 0.2);
}
.b-r{
    border-right: 1px solid rgba($color: $cpa-gray, $alpha: 0.2);
}
.pr-custiom{
    .card-icon{
        padding-left: 25px;
    }
}
@media  (max-width: 1024px)  {
    .specialization-row{
        .img-card{
            margin: auto !important;
        }
        .icon-card{
            width: 80% !important;
            margin: auto;
            text-align: center !important;
            margin-top: 50px;
        }
    }
    .spec-card:nth-of-type(odd) {
        border-bottom: 1px solid rgba($color: $cpa-gray, $alpha: 0.2);
        border-left: 1px solid rgba($color: $cpa-gray, $alpha: 0.2);
    }
    .spec-card:nth-of-type(even) {
        border-bottom: 1px solid rgba($color: $cpa-gray, $alpha: 0.2);
        border-left: 0;
    }
    .spec-card:nth-of-type(5) ,.spec-card:nth-of-type(6) {
        border-bottom: 0;
    }

}
@media  (max-width: 992px){
    .spec-card{
        border-left:0 !important;
        
    }
    .spec-card:not(:last-of-type){
        border-bottom: 1px solid rgba($color: $cpa-gray, $alpha: 0.2) !important;
    }
}
@media (max-width: 425px){
    .specialization-row .icon-card{
        padding: 50px;
        margin: unset;
    }
}