@import "src/styles/variables.scss";
.consumerProtectionAward-container {
    .consumerProtectionAward-content {
        margin-top: 25px;
    }
    h5 {
        color: $cpa-main;
        font-size: 1.25rem;
        margin-bottom: 15px;
        margin-bottom: 20px;
    }
    .row {
        // width: 100%;
    }
    .date {
        font-size: 0.75rem;
        i[class*="icon"] {
            margin-left: 5px;
            color: $cpa-dark-blue;
        }
    }
    .date_by {
        img {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            border: 5px solid #fff;
        }
    }
    .share-control {
        margin-bottom: 25px;
        button {
            padding: 5px;
            width: 45px;
            height: 45px;
            margin-right: 15px;
            transition: width 0.75s ease-in-out;
            overflow: hidden;
        }
    }
    .newsCard-container {
        .card-content {
            padding: 20px;
        }
    }

    .awards-container {
        position: relative;
        margin: 25px 0;
        .winner-card {
            padding: 50px;
            background: #fff;
            margin-bottom: 25px;
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                li {
                    text-align: center;
                    img {
                        width: 95px;
                        height: 95px;
                        border-radius: 50%;
                        margin-bottom: 25px;
                    }
                    h4 {
                        font-size: 1.25rem;
                        color: $cpa-main;
                    }
                }
            }
        }
        .container-card {
            margin-bottom: 25px;
        }
        .container-id-card .card-content {
            padding-top: 15px;
            p {
                margin: 0;
                font-size: 0.85rem;
            }
            span {
                font-size: 0.85rem;
            }
        }
        .nav-item {
            a {
                color: $cpa-defualt;
                font-size: 1rem;
            }
            .active {
                background-color: $cpa-main;
                color: #fff;
            }
        }
    }
    .more {
        text-align: center;
        margin-top: 25px;
        margin-bottom: 35px;
    }
}

.categoryFilterBTNS {
    padding: 5px 25px 5px 25px;
    background: #6be2b7;
    color: white;
    border: unset;
    // box-shadow: 0px 0px 20px 1px #B2B2B2;
    margin: 0px 20px 10px 20px;
}

.categoryFilterBTNS:focus {
    padding: 5px 25px 5px 25px;
    color: $cpa-main;
    background: white;
    border: unset;
    // box-shadow: 0px 0px 20px 1px #B2B2B2;
    margin: 0px 20px 0px 20px;
    border: 1px solid #42c194;
}
.categoryFilterBTNS.active {
    padding: 5px 25px 5px 25px;
    color: $cpa-main;
    background: white;
    border: unset;
    // box-shadow: 0px 0px 20px 1px #B2B2B2;
    margin: 0px 20px 0px 20px;
    border: 1px solid #42c194;
}

.categoryRow {
    margin-bottom: 25px;
    text-align: center;
    .share-control {
        text-align: right;
    }
}

.yearsContainer {
    border-left: 1px;
    padding: 10px;
    button {
        border: 0px solid #6be2b7;
        border-radius: 20px;
        padding: 0px 12px 0px 12px;
        background: unset;
        //color: white;
        &.yearBtns.active {
            background-color: #6be2b7;
        }
    }
    button:focus {
        border: 0px solid #6be2b7;
        border-radius: 20px;
        padding: 0px 12px 0px 12px;
        background: #6be2b7;
        color: white;
    }
}

.pointer {
    cursor: pointer;
    i[class*="icon"] {
        font-size: 3rem;
        color: $cpa-dark-blue;
    }
    i:hover {
        color: $cpa-main;
    }
}

.cardTitle {
    color: $cpa-main;
}

.consumerProtectionAward-container {
    .card-container {
        background-color: white;

        margin-right: 1rem;
        .container-card {
            margin-bottom: 20px;
            width: 100%;
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
            padding: 2%;
            border-radius: 0 !important;
            margin-top: 1rem;
            background-color: white;
            img {
                // height: 166px;
            }
            .inner {
                border: 2px solid #f5f5f7;
                padding: 20px;
                min-height: 0;
                border-radius: 0;
            }
        }
    }
}
@media (max-width: 425px) {
    .consumerProtectionAward-container {
        .col-11 {
            right: 3rem;
        }
        .row {
            .categoryRow {
                .categoryFilterBTNS {
                    margin-bottom: 1rem;
                }
            }
        }
        .yearsContainer {
            border: none;
        }
    }
}
