.articaleCard-container {
  margin-top: 35px; }
  .articaleCard-container .main-header h5 {
    color: #42C194;
    font-size: 1.25rem;
    margin-bottom: 15px; }
  .articaleCard-container .main-header .date {
    font-size: 0.75rem; }
    .articaleCard-container .main-header .date i[class*="icon"] {
      margin-left: 5px;
      color: #215C80; }
  .articaleCard-container .main-header .date_by img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    border: 5px solid #fff; }
  .articaleCard-container .main-img {
    margin-bottom: 56px;
    margin-top: 45px;
    height: 100%; }
    .articaleCard-container .main-img img {
      width: 100%;
      max-height: 350px;
      object-fit: fill; }
    .articaleCard-container .main-img .carousel-item {
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
    .articaleCard-container .main-img .info_graphic_body .slick-prev::before {
      color: #42c194 !important; }
    .articaleCard-container .main-img .info_graphic_body .slick-arrow.slick-next::before {
      color: #42c194; }
    .articaleCard-container .main-img .carousel-control-next {
      opacity: 1;
      color: #42c194; }
    .articaleCard-container .main-img .carousel-control-prev {
      opacity: 1;
      color: #42c194; }
  .articaleCard-container .main-content {
    font-size: 1.125rem; }

.articale .news-date {
  display: none; }

.news .date_by {
  display: none; }

@media (max-width: 500px) {
  .articaleCard-container .main-img img {
    height: auto; } }
