@import 'src/styles/variables.scss';
.homePage-container{
    line-height: 30px;
    .embed-responsive{
        height: 100vh;
        .embed-responsive-item {
          height: unset;
          width: unset;
        }
      }
    .top-head{
        height: 100vh;
        position: relative;
        .carousel{
            height: 100%;
            .carousel-inner{
                height: 100%;
                .carousel-item{
                    height: 100%;
                    img{
                    //   height: 100%;
                    height: 200px;
                    }
                    .carousel-caption{
                        top: 35%;
                        bottom: auto;
                        h3{
                            color: $cpa-main;
                            margin-bottom: 1.625rem;
                            margin-bottom: 17px;
                        }
                        p{
                            font-size: 1rem;
                        }
                        .btn-toolbar{
                            margin-top: 35px;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            .btn-outline-success{
                                margin-right: 15px;
                                margin-bottom: 15px;
                                width: 95%;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width:768px) {
            height: auto;
            .carousel .carousel-inner .carousel-item .carousel-caption{
                top:40%;
            }
        }
        @media (max-width:425px) {
            .carousel-indicators .active{
                display: none;
            }
        }
    }
}

@media (max-width:768px) {
    .homePage-container .embed-responsive{
        height: auto;
        .embed-responsive-item {
            width: 100%;
        }
    }
}
/* .carousel-caption .btn{
margin: 10px;
min-width: 10rem;
height: 46px;
padding: 0;
border-width: 2px;
} */
.btn-outline-success{
    color: #fff !important;
    border-color: #fff !important;
}
.btn-outline-success:hover{
    color: #fff !important;
    background-color: $cpa-main !important;
    border-color: $cpa-main !important;
}
.details-slider-caption .btn-outline-success:hover{
    color: #fff !important;
    background-color: $cpa-main !important;
    border-color: $cpa-main !important;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle{
    color: #fff !important;
    background-color: $cpa-main !important;
    border-color: $cpa-main !important;
}
.btn-success{
    color: #fff !important;
    background-color: $cpa-main !important;
    border-color: $cpa-main !important;
}
.btn-success:hover{
    color: #fff !important;
    background-color: $cpa-main !important;
    border-color: $cpa-main !important;
}
.btn-toolbar{
    justify-content: center ;
}
@keyframes example {
    0%  {
        transform: translateY(3px);
        transition: ease-in-out;
    }
    50%  {
        transform: translateY(-3px);
        transition: ease-in-out;
    }
    100% {
        transform: translateY(3px);
        transition: ease-in-out;
    }
  }
.top-head{
    position: relative;
    // .carousel-indicators{
    //     bottom: 22px;
    //     li{
    //       animation-name: example;
    //       animation-duration: 2s;
    //       animation-iteration-count:infinite;
    //       height: 28px !important;
    //       background-color: transparent !important;
    //       border: 2px solid #fff;
    //       border-radius: 25px;
    //       position: relative;
    //       width: 16px !important;
    //       border-top: 1px solid #fff !important;
    //       border-bottom: 1px solid #fff !important;
    //       &:after{
    //           position:absolute;
    //           content: "";
    //           top:5px;
    //           right: 7px;
    //           height: 8px;
    //           width: 2px;
    //           background-color: #fff;
    //       }
    //     //   &:before{
    //     //     position: absolute;
    //     //     bottom: -9px;
    //     //     right: 4px;        
    //     //     vertical-align: 0rem;
    //     //     content: "" ;
    //     //     transform: rotate(45deg);
    //     //     border: solid #fff;
    //     //     border-width: 0 3px 3px 0;
    //     //     display: inline-block;
    //     //     padding: 2px;
    //     //   }
    //     }
    //     @media (max-width:768px) {
    //         bottom: 0;
    //     }
    // }
}
