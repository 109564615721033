@import "src/styles/variables.scss";

.MotamakenSingle-container {
    .share-link {
        cursor: pointer;
    }
    .share-control {
        // display: none;
        &.active {
            // display: block;
            background-color: transparent;
        }
    }
    .remainig-days {
        text-align: right;
        font-size: 1.5rem;
        color: #215c80;
    }
    .comparingBar {
        .card {
            width: 18rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            .btn.submit-cpa-btn {
                width: 1px;
                display: flex;
                background-color: #6be2b7;
                color: white;
                justify-content: center;
                padding: 6px 18px;
            }
            .btn.submit-cpa-btn:hover {
                background-color: white;
                color: #42c194;
            }
        }
    }

    .single-info {
        margin-bottom: 2rem;
        .container {
            h4 {
                font-size: 1.5rem;
                font-weight: bold;
                color: $cpa-main;
                margin: 0 0 2.5rem;
                padding: 0;
            }
            .title {
                font-size: 1.5rem;
                font-weight: bold;
                color: #215c80;
                margin: 0 0 2.5rem;
                padding: 0;
            }
            p {
                font-size: 1rem;
                padding: 0;
                margin: 1.5rem 0 0;
                text-align: justify;
            }
            .author-wrapper {
                display: flex;
                width: 100%;
                margin: 0;
                padding: 0 0 1.5rem;
                img {
                    display: inline-block;
                    border-radius: 50%;
                    margin: 0;
                    width: 50px;
                    height: 50px;
                    box-shadow: 0 0 3px 2px #999;
                }
                p {
                    font-size: 1.2rem;
                    font-weight: bold;
                    display: inline-block;
                    color: $cpa-black;
                    margin: 0;
                    padding: 0 10px;
                }
            }
            .single-reg {
                width: 100%;
                border-radius: 10px;
                border: 2px solid #fff;
            }
            img {
                width: 100%;
                border-radius: 10px;
                margin: 1rem auto;
            }
            .counter-wrapper {
                .date-content {
                    display: flex;
                    // width: 30rem;
                }
                .days-counter {
                    display: flex;
                }
                .date-counter {
                    color: #42c194 !important;
                }

                display: flex;
                width: 100%;
                margin: 0;
                padding: 0;
                flex-direction: row;
                justify-content: space-between;
                .row {
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    span {
                        font-size: 1rem;
                        margin: 0;
                        padding: 0;
                        color: $cpa-gray;
                        display: flex;

                        i[class*="icon"] {
                            color: $cpa-dark-blue;
                            margin-left: 5px;
                            font-size: 0.8rem;
                            vertical-align: middle;
                        }
                        .icon-location {
                            display: inline-block;
                            font-size: 1.1rem !important;
                            margin-right: -5px;
                        }
                    }
                    .location-details {
                        padding: 0 24px !important;
                    }
                }
            }
        }
    }
    .single-content {
        .carousel-item {
            // height: 350px;
            img {
                display: block;
                object-fit: fill;
                // height: 100%;
            }
        }
        .carousel-control-prev {
            top: 40%;
            height: 10%;
        }
        .carousel-control-next {
            top: 40%;
            height: 10%;
        }
        h4 {
            font-size: 1.5rem;

            font-weight: bold;
            color: $cpa-dark-blue;
            margin: 0 0 1.5rem;
            padding: 0;
        }
        .gallery-container {
            position: relative !important;
        }
        .links-attach {
            width: 100%;
            background-color: #fff;
            padding: 2rem;
            margin: 2rem 0;
            border-radius: 10px;
            height: auto;
            font-size: 1rem;
            a {
                margin: 0 0 1rem;
                color: $cpa-gray;
                display: block;
                &:hover {
                    color: $cpa-main !important;
                    text-decoration: none;
                }
                i[class*="icon"] {
                    margin-left: 1rem;
                }
            }
        }
    }
}
