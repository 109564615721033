@import 'src/styles/variables.scss';
.card-file{
    background: #fff;
    border:1px solid #e2e2e2;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 25px;
    box-shadow: 0 1px 4px $cpa-gray;
    cursor: pointer;
    i[class*="icon"]{
        font-size: 1.5rem;
        color:$cpa-dark-blue;
    }
    h4{
        color:$cpa-main !important;
        font-size: 1rem;
        margin: 0 !important;
    }
    &:hover{
        h4{
            font-weight: bold;
        }
        box-shadow: 0 8px 9px $cpa-gray;
    }
    
}