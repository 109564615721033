@import "src/styles/variables.scss";

.container-members {
    .content {
        // margin-top: 95px;
        .title {
            font-family: "Cairo-Regular";
            color: $cpa-dark-blue;
            font-size: 1.625rem;
            margin: 35px auto;
        }
        .container-card {
            margin-bottom: 20px;
            width: 100%;
            background-color: white;
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
            padding: 2%;
            border-radius: 0;
        }
        .container-card {
            img {
                // height:166px;
                max-height: 350px;
            }
            .inner {
                border: 2px solid #f5f5f7;
                padding: 20px;
                min-height: 0;
                border-radius: 0;
            }
        }
    }
}
.pagination {
    width: fit-content;
    padding-top: 20px;
    padding-bottom: 90px;
    margin: auto;
}
.dark-blue {
    color: $cpa-dark-blue !important;
    i[class*="icon"] {
        color: $cpa-dark-blue !important;
    }
}
.pointer {
    cursor: pointer;
    i[class*="icon"] {
        font-size: 1.3em;
        color: $cpa-dark-blue;
    }
    i:hover {
        color: $cpa-main;
    }
}
.members-modal {
    .card-img {
        max-width: 285px;
        height: 250px;
        margin: auto;
    }
    .modal-header {
        .close {
            span:first-of-type {
                left: 4px;
            }
        }
    }
}
