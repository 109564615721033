.header-wrapper {
    background-color: transparent;
    height: 115px;
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    color: #000;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.header-shrink {
    background-color: #fff;
    height: 66px;
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    color: #000;
    box-shadow: 0 1px 7px #ccc;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.toolbar_cpa {
    display: flex;
    justify-content: space-between;
}
.header-wrapper .visebal-view,
.header-shrink .visebal-view {
    display: block;
}
.header-wrapper .invesible-view,
.header-shrink .invesible-view {
    display: none;
}
.spacer {
    flex: 1;
}
.header-wrapper .header-icons {
    padding: 0 !important;
    display: flex;
    vertical-align: middle;
    justify-content: space-between;
    height: 115px;
    align-items: center;
    color: #fafafc;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.header-shrink .header-icons {
    padding: 0 !important;
    display: flex;
    vertical-align: middle;
    justify-content: space-between;
    height: 66px;
    align-items: center;
    color: #215c80;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.header-icons .icon-wrapper {
    width: 30px;
    height: 30px;
    /* border: 3px solid #fafafc; */
    border-radius: 50%;
    display: flex;
    align-self: center;
    cursor: pointer;
    /* margin: 0 5px; */
}
.side-drawer-buttons .user-validation {
    width: 100%;
}
.header-icons .icon-wrapper:first-child {
    margin-left: 15px;
}
.user-validation a {
    color: #fff;
}
.user-validation:hover {
    color: #42c194;
}

.header-icons .icon-wrapper .icon-logout {
    font-size: 1.25rem;
}
.header-icons .icon-wrapper i.translate-icon {
    margin-top: -3px;
    text-transform: uppercase;
}
.header-icons .icon-wrapper i:hover {
    color: #42c194;
}

/* the search part */
.search-form {
    position: relative;
    top: 23%;
    left: 25%;
    transform: translate(-50%, -50%);
    transition: all 1s;
    width: 50px;
    height: 50px;
    background: transparent;
    box-sizing: border-box;
    border-radius: 25px;
    border: 4px solid transparent;
    padding: 5px;
}

.search-input {
    position: relative;
    top: 0;
    left: 0;
    width: 600%;
    height: 35px;
    line-height: 30px;
    outline: 0;
    border: 0;
    display: none;
    font-size: 1em;
    border-radius: 20px;
    padding: 0 40px;
}

.icon-search-1 {
    box-sizing: border-box;
    padding: 7px;
    width: 42.5px;
    height: 42.5px;
    position: absolute;
    top: 0;
    /* right: 1rem; */
    right: 0;
    border-radius: 50%;
    /* color: #07051a; */
    text-align: center;
    font-size: 1.2em;
    transition: all 1s;
}

.header-shrink .search-form {
    top: 26px;
}

.header-shrink .search-input {
    background-color: #215c80;
    color: white;
}

.header-shrink .search-history-show {
    top: 55px;
}

.search-history-hide {
    position: absolute;
    top: 77px;
    left: 0;
    background-color: white;
    display: grid;
    width: 9rem;
    padding: 1rem;
    border-radius: 10%;
    display: none;
}

.search-history-show {
    position: absolute;
    top: 77px;
    left: 0;
    background-color: white;
    display: grid;
    width: 9rem;
    padding: 1rem;
    border-radius: 10%;
}

.icon-search-1:hover .search-form {
    width: 400px;
    cursor: pointer;
}

.search-form:hover input {
    display: block;
}

.search-form:hover .icon-search-1 {
    /* background: #07051a; */
    color: #215c80;
    right: 0;
}

.header-shrink .search-form:hover .icon-search-1 {
    color: white;
    right: 0;
}

.dropdown-toggle::after {
    margin-left: -106% !important;
    display: inline-block !important;
    vertical-align: 0rem !important;
    content: "\F004" !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-left: none !important;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block !important;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
}
.navbar-nav .dropdown-menu {
    padding: 0;
    width: fit-content;
    width: -moz-fit-content;
}
.header-wrapper .navbar-nav .dropdown-menu {
    top: 70px;
    width: max-content;
}

.dropdown-menu .dropdown-item {
    line-height: 1;
    padding: 10px 15px;
    font-size: 0.875rem;
}
/* .dropdown-menu a.dropdown-item::after{
  color: #06a74f;
  display: inline-block;
  vertical-align: 0rem;
  content: '\e801';
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0;
  margin-left: 10px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
} */

/* .dropdown-menu a.dropdown-item a{
  color:#000;
  text-decoration: none;
  background-color: transparent !important;
} */
/* .dropdown-menu .dropdown-item:last-child{
  padding-bottom: 0;
} */
.dropdown-item:hover {
    color: #42c194;
    text-decoration: none;
    background-color: transparent !important;
    /* font-size: 1.1rem; */
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.dropdown-item:focus,
.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #42c194;
}

/*----------------------------------------------------------------------------------------*/

.nav-item-cpa {
    display: flex;
    margin: 0 10px;
    color: #282827;
}

.header-wrapper .nav-link {
    color: #fff !important;
    text-decoration: none;
    padding: 0 1.5rem;
    font-weight: 500;
}
/* .dropdown-menu a.dropdown-item a:hover{
  color: #42C194 !important;
} */
.header-shrink .nav-link {
    color: #215c80 !important;
    text-decoration: none;
    padding: 0 1.5rem;
    font-weight: 500;
}
.dropdown-menu {
    top: 90%;
}
.navbar-default {
    background-image: none;
    border: none;
    border-radius: 0;
}
.navbar-header {
    width: 100%;
}
.header-wrapper .navbar-nav,
.header-shrink .navbar-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.header-wrapper .navbar-content-main {
    height: 100%;
    line-height: 100px;
    position: relative;
}
.navbar-content-main .beta_span {
    position: absolute;
    color: #fff;
    bottom: 0;
    right: 45px;
    height: 45px;
    width: 125px;
    transition: 0.5s all ease-in-out;
}
.navbar-content-main .beta_span span {
    background-color: #42c194;
    font-size: 0.75rem;
    padding: 0 15px;
    border-radius: 15px;
}

.isScrolling .navbar-content-main .beta_span {
    /* right: -55px;
  transition: 0.5s all ease-in-out; */
    display: none;
}

@media (max-width: 768px) {
    .header-wrapper .navbar-content-main {
        line-height: 80px;
    }
}
.header-shrink .navbar-content-main {
    height: 100%;
    line-height: 45px;
}

.navbar-default .navbar-nav > li > a {
    color: white;
    text-decoration: none;
    display: block;
    cursor: pointer;
    font-weight: bold;
}

.header-wrapper .navbar-brand,
.header-shrink .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    width: 100%;
    align-items: center;
    display: flex;
    text-align: center;
}

.header-wrapper .navbar-brand .cpaLogo.unshrink {
    width: 3em;
}

.header-wrapper .navbar-brand img {
    /* width: 6em; */
    width: 3em;
    height: 100%;
    margin-top: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    padding: 10px;
}

.header-shrink .navbar-brand img {
    width: 22%;
    height: auto;
    margin-top: 5px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.header-wrapper .navbar-brand img.shrink {
    width: 0;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
}
.header-shrink .navbar-brand img.unshrink {
    width: 0;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
}

.header-wrapper .share-button,
.header-shrink .share-button {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    color: #fff;
    font-size: 2rem;
    height: 100%;
    cursor: pointer;
}
.header-shrink .share-button {
    color: #42c194;
}
.cpa-share {
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.share-button:focus {
    outline: none;
}
.navbar-nav .dropdown-menu {
    top: 90%;
}

/* /////////////////////////////////////////////////////////

//////// Media Query

////////////////////////////////////////////////////////
 */

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    .header-wrapper .visebal-view,
    .header-shrink .visebal-view {
        display: none;
    }
    .header-wrapper .invesible-view,
    .header-shrink .invesible-view {
        display: block;
    }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .header-wrapper .visebal-view,
    .header-shrink .visebal-view {
        display: none;
    }
    .header-wrapper .invesible-view,
    .header-shrink .invesible-view {
        display: block;
    }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 320px) and (max-width: 767px) {
    .header-wrapper .visebal-view,
    .header-shrink .visebal-view {
        display: none;
    }
    .header-wrapper .invesible-view,
    .header-shrink .invesible-view {
        display: block;
    }
    .header-wrapper .navbar-brand,
    .header-shrink .navbar-brand {
        width: 160%;
    }
    .header-shrink .navbar-brand img {
        width: 40%;
    }
    /* .header-wrapper .navbar-brand img{
    width: 190%;
  } */
}
@media (max-width: 574px) {
    .header-wrapper .navbar-brand img {
        width: 2.5em;
    }
}
@media (max-width: 320px) {
    /* .header-wrapper .navbar-brand img{
    width: 295%;
  } */

    .header-wrapper .visebal-view,
    .header-shrink .visebal-view {
        display: none;
    }
    .header-wrapper .invesible-view,
    .header-shrink .invesible-view {
        display: block;
    }
    .header-wrapper .navbar-brand,
    .header-shrink .navbar-brand {
        width: 160%;
    }
    .header-shrink .navbar-brand img {
        width: 40%;
    }
}
