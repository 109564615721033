@import 'src/styles/variables.scss';
.ComprehensiveGuide-container{
    .ComprehensiveGuide-content{
        margin-top: 25px;
        div [class*=col]{
            
            margin-top: 25px;
        }
        .guide-card{
            height: 100%;
            text-align: center;
            background-color: #fff;
            padding: 35px;
            border-radius: 10px;
            cursor: pointer;
            color:$cpa-defualt;
            &:hover{
                box-shadow: 0 8px 9px #999;
                h4{
                    font-weight: bold;
                }
            }
            .inner{
                height: 100%;
                padding: 25px;
                border: 2px solid #42C194;
                border-radius: 10px;
                ul{
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    i[class*="icon"]{
                        color:$cpa-main;
                        font-size: 2rem;
                    }
                }
            }
        }
    }
    .shakwa-section{
        margin:35px 0;
    }
    .landing-card{
        .inner{
            height: 55px;
        }
    }

    .sharing-downloading--container{
        display: flex;
        flex-direction: row;
        right: 11rem;
        .share-link{
            padding-top: 0.4rem;
            cursor: pointer;
        }
        .share-control{
            position: absolute;
            margin-bottom: 0; 
            background-color: white;
            box-shadow: 0 8px 9px #809696;
            border-radius: 10px;
            display: none;
            z-index: 999;
            top: 4rem;
            &.active{
                display: block;
            }
        }    
    }
    .dropdown{
        .dropdown-toggle::after{
            margin-right:7% !important;
        }
    }    
}
.modal-container{
    ul{
        list-style: none;
        li{
            font-size: 1rem;
        }
    }
    h4{
        font-size: 1.5rem;
        color:$cpa-main
    }
    span{
        margin: 20px 0;
        color:$cpa-gray;
    }
    h5{
        font-size: 1.25rem;
        color:$cpa-dark-blue;
        margin-top: 25px;
    }
    .inner-list{
        list-style-type: arabic-indic;
        margin-top: 25px;
        li{
            padding-right:15px;
            
        }
    }
}

@media(max-width:425px){
    .ComprehensiveGuide-container {
        .sharing-downloading--container{
            right: unset;
        }
    }
}