@import 'src/styles/variables.scss';
.landing-container{
    .landing-row{
        &:first-of-type{
            margin-top: 50px;
        }
        // .landing-gird{
        //     max-height: 126px;
        // }
        .landing-card{
            cursor: pointer;
            text-align: center;
            padding: 13%;
            padding-left:unset ;
            padding-right:unset ;
            background: #fff;
            margin-bottom: 25px;
            box-shadow: 0 1px 4px $cpa-gray;
            border-radius: 10px;
            // min-height: 200px;
            &.icon{
                height: 380px;
                padding: 5%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .author-wrapper{
                    span{
                        display: flex;
                        align-items: flex-start;
                    }
                }
            }
            ul{
                list-style: none;
                text-align: center;
                padding: 0;
                margin: 0;
                li{
                    i[class*="icon"]{
                        font-size: 2rem;
                        color:$cpa-main
                    }
                    h3,h5{
                        color:$cpa-dark-blue;
                        margin: 0
                    }
                    p{
                        font-size: 1rem;
                        color:$cpa-gray;
                    }
                    .icon-Observation{
                        position: relative;
                        right: -10px;
                    }
                }
            }
            &:hover{
                box-shadow: 0 8px 9px $cpa-gray;
                h3,h5{
                    font-weight: bold;
                }
            }
            h3{
                color:$cpa-dark-blue;
                text-align: center;
            }
            &--body{   
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-left: 13%;
                padding-right: 13%;
                .icon-calendar,.icon-clock{
                    margin-inline-end: .5rem;
                    color:$cpa-dark-blue;
                }
            }
        }
    }
}
