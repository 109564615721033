@import "src/styles/variables.scss";
.annualReports-container {
    .reports-content {
        margin-top: 80px;

        .report-card {
            background: #fff;
            padding: 42px 36px;
            text-align: center;
            margin-bottom: 30px;
            height: 383px;
            position: relative;
            .report-img {
                width: 100%;
                height: 225px;
                margin-bottom: 31px;
                img {
                    // width: 100%;
                    max-width: 100%;
                    height: 100%;
                }
            }
            .report-title {
                position: absolute;
                right: 0;
                left: 0;
                background: #fff;
                padding-top: 25px;
                bottom: 25px;
                height: 75px;
                overflow: hidden;
                transition: 0.75s ease-in-out;

                h4 {
                    color: $cpa-main;
                    font-size: 1.125rem;
                    margin-bottom: 32px;
                }
                .report-controllers {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    a,
                    button {
                        font-size: 0.875rem;
                        padding: 12px 25px;
                        border-radius: 5px;
                        span {
                            position: relative;
                            top: -2px;
                        }
                        i[class*="icon"] {
                            margin-left: 10px;
                        }
                        &:not(:last-of-type) {
                            margin-left: 10px;
                        }
                    }
                }
            }
            &:hover {
                .report-title {
                    bottom: 35px;
                    height: 150px;
                    transition: 0.75s ease-in-out;
                }
            }
        }
    }
    .modal-content {
        background-color: transparent !important;
        border: unset !important;
        .container-card {
            width: 100%;
            background: transparent;
            box-shadow: unset;
            .inner {
                border: unset;
                padding: unset;
                .modal-header {
                    .close {
                        position: absolute;
                        right: -10px;
                        top: -10px;
                    }
                }
                .modal-body {
                    height: 85vh !important;
                }
            }
        }
    }
}
