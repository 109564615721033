.profile-card-container{
    width: 30%;
    margin-bottom: 5rem;
    margin-inline-end: 2rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,.075);
    border-radius: 7px;
    padding: 1rem;
    &-image{
        width: 100%;
        margin-bottom: 1rem;
        img{
            width: 100%;
            height: 8rem;
        }
    }
    &-details{
        &-title{
            color: #6BE2B7;
            font-size: 1.125rem;
        }
        &-btns{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 2rem;
            .cer-date{
                font-size: 0.75rem;
            }
            .show-btn{
                background-color: #6BE2B7;
                border: unset;
                border-radius: 7px;
                color: white;
                cursor: pointer;
                padding: 0.5rem 1rem 0.5rem 1rem;
            }
        }
    }
}