@import "src/styles/variables.scss";

.worldDayPage-conatiner {
    .align-dates {
        align-items: center;
    }
    .share-control {
        text-align: right;
    }
    .info_graphic_body {
        img {
            max-width: 25rem;
            max-height: 35rem;
            cursor: pointer;
        }
        .slick-arrow.slick-prev::before {
            color: #42c194;
        }
        .slick-arrow.slick-next::before {
            color: #42c194;
        }
    }

    .data-container {
        .newsCard-container {
            width: 25rem;
            // margin-left: 2rem;
            height: 80%;
            .cpa-tooltip {
                .cpa-tooltiptext {
                    top: 35px;
                    left: 1px;
                }
            }
        }
    }

    .news-container {
        .newsCard-container {
            .card-img {
                img {
                    display: inline-block;
                }
            }
            width: 25rem;
            // margin-left: 2rem;
            height: 80%;
            .cpa-tooltip {
                .cpa-tooltiptext {
                    top: 35px;
                    left: 1px;
                }
                h4 {
                    white-space: wrap !important;
                    overflow: unset !important;
                    margin: 0 0 20px;
                    white-space: pre-wrap;
                }
            }
        }
    }
    .motamaken-container {
        .newsCard-container {
            width: 25rem;
            // margin-left: 2rem;
            .action-hover {
                h5 {
                    text-align: right;
                }
            }
            .card-content {
                text-align: right;
            }
        }
    }
    .consumer-advisor-container {
        .newsCard-container {
            width: 25rem;
            // margin-left: 2rem;
            .consumer-action-hover {
                text-align: right;
            }
            .card-content {
                padding: 2.25rem;
                text-align: right;
            }
        }
    }
    text-align: center;
    .news-container,
    .data-container,
    .consumer-advisor-container,
    .motamaken-container {
        display: flex;
        justify-content: space-between;
        // overflow-y:auto;
        // overflow-x:hidden;
        // flex-wrap: wrap;
        // height: 26rem;
        .demo-4.medium {
            width: 100%;

            .slick-arrow.slick-prev::before {
                color: #42c194;
            }

            .slick-arrow.slick-next::before {
                color: #42c194;
            }

            .el-carousel__item--card {
                width: 22rem;
            }
        }
    }
    h4 {
        color: $cpa-dark-blue;
        margin: 25px 0;
        text-align: right;
    }

    .img-container {
        img {
            width: 100%;
        }
    }

    .header-img {
        max-width: 70%;
        width: 70%;
        margin-top: 2%;
    }

    .header-title {
        margin-top: 4%;
        color: $cpa-main;
        text-align: right;
    }

    .day-description {
        margin-top: 1rem;
        text-align: right;
        color: $cpa-gray;
        margin-bottom: 2rem;
    }
}

.vertical-menu {
    cursor: pointer;
    width: 100px;
    // margin-top: 25%;
    position: relative;
    // top: 8rem;
    // z-index: 999;
    /* Set a width if you like */
}

.vertical-menu span {
    background-color: #f8f9fa;
    /* Grey background color */
    color: black;
    /* Black text color */
    display: block;
    /* Make the links appear below each other */
    padding: 2px;
    /* Add some padding */
    text-decoration: none;
    /* Remove underline from links */
}

.vertical-menu span:hover {
    background-color: #eee;
    /* Dark grey background on mouse-over */
}

.vertical-menu span.active {
    background-color: $cpa-main;
    border-radius: 25px;
    /* Add a green color to the "active/current" link */
    color: white;
}

// @media (max-width: 425px) {
//     .worldDayPage-conatiner {
//         .vertical-menu {
//             top: 3%;
//             right: 4rem;
//         }
//         .header-img {
//             max-width: 100%;
//             width: 100%;
//         }
//         .newsCard-container {
//             width: 100% !important;
//         }

//         .info-graphic {
//             margin-top: unset;
//         }
//         .vertical-menu {
//             width: 40px;
//         }
//     }
// }

@media (max-width: 1000px) {
    .worldDayPage-conatiner {
        .share-control {
            text-align: right;
        }
        .info_graphic_body {
            img {
                max-width: 22rem;
                // max-height: 22rem;
                cursor: pointer;
            }
            .slick-arrow.slick-prev::before {
                color: #42c194;
            }
            .slick-arrow.slick-next::before {
                color: #42c194;
            }
        }

        .data-container {
            .newsCard-container {
                width: 22rem;
                // margin-left: 2rem;
                height: 80%;
                .cpa-tooltip {
                    .cpa-tooltiptext {
                        top: 35px;
                        left: 1px;
                    }
                }
            }
        }

        .news-container {
            .newsCard-container {
                .card-img {
                    img {
                        display: inline-block;
                    }
                }
                width: 22rem;
                // margin-left: 2rem;
                height: 80%;
                .cpa-tooltip {
                    .cpa-tooltiptext {
                        top: 35px;
                        left: 1px;
                    }
                    h4 {
                        white-space: wrap !important;
                        overflow: unset !important;
                        margin: 0 0 20px;
                        white-space: pre-wrap;
                    }
                }
            }
        }
        .motamaken-container {
            .newsCard-container {
                width: 22rem;
                // margin-left: 2rem;
                .action-hover {
                    h5 {
                        text-align: right;
                    }
                }
                .card-content {
                    text-align: right;
                }
            }
        }
        .consumer-advisor-container {
            .newsCard-container {
                width: 22rem;
                // margin-left: 2rem;
                .consumer-action-hover {
                    text-align: right;
                }
                .card-content {
                    padding: 2.25rem;
                    text-align: right;
                }
            }
        }
        text-align: center;
        .news-container,
        .data-container,
        .consumer-advisor-container,
        .motamaken-container {
            display: flex;
            justify-content: space-between;
            // overflow-y:auto;
            // overflow-x:hidden;
            // flex-wrap: wrap;
            // height: 26rem;
            .demo-4.medium {
                width: 100%;

                .slick-arrow.slick-prev::before {
                    color: #42c194;
                }

                .slick-arrow.slick-next::before {
                    color: #42c194;
                }

                .el-carousel__item--card {
                    width: 22rem;
                }
            }
        }
        h4 {
            color: $cpa-dark-blue;
            margin: 15px 0;
            text-align: right;
        }

        .img-container {
            img {
                width: 100%;
            }
        }

        .header-img {
            max-width: 70%;
            width: 70%;
            margin-top: 2%;
        }

        .header-title {
            margin-top: 4%;
            color: $cpa-main;
            text-align: right;
        }

        .day-description {
            margin-top: 1rem;
            text-align: right;
            color: $cpa-gray;
            margin-bottom: 2rem;
        }
    }

    .vertical-menu {
        cursor: pointer;
        width: 100px;
        // margin-top: 25%;
        position: relative;
        // top: 8rem;
        // z-index: 999;
        /* Set a width if you like */
    }

    .vertical-menu span {
        background-color: #f8f9fa;
        /* Grey background color */
        color: black;
        /* Black text color */
        display: block;
        /* Make the links appear below each other */
        padding: 2px;
        /* Add some padding */
        text-decoration: none;
        /* Remove underline from links */
    }

    .vertical-menu span:hover {
        background-color: #eee;
        /* Dark grey background on mouse-over */
    }

    .vertical-menu span.active {
        background-color: $cpa-main;
        border-radius: 25px;
        /* Add a green color to the "active/current" link */
        color: white;
    }
}
@media (max-width: 750px) {
    .worldDayPage-conatiner {
        .share-control {
            text-align: right;
        }
        .day-description {
            width: 100%;
            img {
                width: 100%;
                display: inline-block;
                object-fit: fill;
            }
        }

        .info_graphic_body {
            .slick-list {
                width: 90%;
                margin: auto;
            }
            .slick-prev {
                right: -15px;
            }
            .slick-next {
                left: -11px;
            }
            img {
                max-width: 22rem;
                // max-height: 22rem;
                cursor: pointer;
            }
            .slick-track {
                width: 750px;
            }
            .slick-arrow.slick-prev::before {
                color: #42c194;
            }
            .slick-arrow.slick-next::before {
                color: #42c194;
            }
        }

        .data-container {
            .newsCard-container {
                width: 100%;
                // margin-left: 2rem;
                height: 80%;
                .cpa-tooltip {
                    .cpa-tooltiptext {
                        top: 35px;
                        left: 1px;
                    }
                }
            }
        }

        .news-container {
            .a {
                width: auto !important;
                max-width: 350px;
                min-width: 250px;
            }
            .newsCard-container {
                width: 100%;
                .card-img {
                    img {
                        width: 100%;
                        display: inline-block;
                        height: 80%;
                        // display: inline-block;
                    }
                }
                // width: 22rem;
                // margin-left: 2rem;
                height: 80%;
                .cpa-tooltip {
                    .cpa-tooltiptext {
                        top: 35px;
                        left: 1px;
                    }
                    h4 {
                        white-space: wrap !important;
                        overflow: unset !important;
                        margin: 0 0 20px;
                        white-space: pre-wrap;
                    }
                }
            }
        }
        .motamaken-container {
            .newsCard-container {
                width: 100%;
                // margin-left: 2rem;
                .action-hover {
                    h5 {
                        text-align: right;
                    }
                }
                .card-content {
                    text-align: right;
                }
            }
        }
        .consumer-advisor-container {
            .newsCard-container {
                width: 100%;
                // margin-left: 2rem;
                .consumer-action-hover {
                    text-align: right;
                }
                .card-content {
                    padding: 2.25rem;
                    text-align: right;
                }
            }
        }
        text-align: center;
        .news-container,
        .data-container,
        .consumer-advisor-container,
        .motamaken-container {
            display: flex;
            justify-content: space-between;
            // overflow-y:auto;
            // overflow-x:hidden;
            // flex-wrap: wrap;
            // height: 26rem;
            .demo-4.medium {
                width: 90%;
                margin: auto;

                .slick-arrow.slick-prev::before {
                    color: #42c194;
                }

                .slick-arrow.slick-next::before {
                    color: #42c194;
                }

                .el-carousel__item--card {
                    width: 22rem;
                }
            }
        }
        .consumer-advisor-container,
        .data-container {
            > div {
                width: 90%;
                margin: auto;
                > a {
                    width: 90%;
                    display: inline-block;
                }
            }
        }
        h4 {
            color: $cpa-dark-blue;
            margin: 15px 0;
            text-align: right;
        }

        .img-container {
            img {
                // width: 85%;
                // height: 185px;
            }
        }

        .header-img {
            max-width: 70%;
            width: 70%;
            margin-top: 2%;
        }

        .header-title {
            margin-top: 4%;
            color: $cpa-main;
            text-align: right;
        }

        .day-description {
            margin-top: 1rem;
            text-align: right;
            color: $cpa-gray;
            margin-bottom: 2rem;
        }
    }

    .vertical-menu {
        cursor: pointer;
        width: 100px;
        // margin-top: 25%;
        position: relative;
        // top: 8rem;
        // z-index: 999;
        /* Set a width if you like */
    }

    .vertical-menu span {
        background-color: #f8f9fa;
        /* Grey background color */
        color: black;
        /* Black text color */
        display: block;
        /* Make the links appear below each other */
        padding: 2px;
        /* Add some padding */
        text-decoration: none;
        /* Remove underline from links */
    }

    .vertical-menu span:hover {
        background-color: #eee;
        /* Dark grey background on mouse-over */
    }

    .vertical-menu span.active {
        background-color: $cpa-main;
        border-radius: 25px;
        /* Add a green color to the "active/current" link */
        color: white;
    }
}
