@import "src/styles/variables.scss";
.breadCrumb-container {
    background-color: #fff !important;
    // height: 55px;
}
.breadcrumb {
    background-color: #fff !important;
    padding: 20px 100px;
    border: 0;
    border-bottom: 1px;
    border-color: $cpa-light-gray;
    border-style: solid;
    font-size: 0.875rem;
    position: relative;
    a {
        color: $cpa-defualt;
    }
    .filter {
        min-width: 250px;
    }
    .breadcrumb-item:not(:last-of-type) {
        margin-left: 15xp;
        a {
            color: $cpa-main;
        }
    }
    .breadcrumb-item + .breadcrumb-item::before {
        content: ">";
        color: $cpa-main;
        margin-left: 15px;
    }

    position: relative;
    .dropdown {
        position: absolute;
        left: 10vw;
        top: 10px;
        text-align: right;
        &:nth-of-type(2) {
            left: 21vw;
        }
        &:last-of-type {
            left: 31vw;
        }
        button {
            color: $cpa-defualt;
            border-color: $cpa-defualt;
            background-color: #fff;
            font-size: 0.75rem;
            span {
                position: relative;
                right: -5px;
                top: -3px;
            }
            &:after {
                margin-left: 0;
            }
        }
        .btn-primary:not(:disabled):not(.disabled).active,
        .btn-primary:not(:disabled):not(.disabled):active,
        .show > .btn-primary.dropdown-toggle {
            color: $cpa-defualt;
            border-color: $cpa-defualt;
            background-color: #fff;
        }
        button:focus {
            color: $cpa-defualt;
            border-color: $cpa-defualt;
            background-color: #fff;
        }
    }
}
