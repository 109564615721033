@import 'src/styles/variables.scss';
.news-conatiner{
    font-family: 'Cairo-Regular';
    .title{
        color: $cpa-main;
        font-size: 1.5rem;
        margin: 36px 0;
    }
    .embed-responsive{
        height: 0;
    }
    .new-news{
        .newsCard-container{
            margin-bottom: 24px;
            .card-content{
                padding: 25px 15px;
            }
        }
    }
    .more-container{
        margin-top: 55px;
        .sub-title{
            color: $cpa-dark-blue;
            font-size: 1.5rem;
            margin-bottom: 26px;
        }
        .news-tweet{
            padding: 37px 49px;
            line-height: 30px;
            margin-bottom: 50px;
            .tweet-img{
                margin-bottom: 40px;
                width: 55px;
                img{
                    width:100%;
                }
            }
            .tweet-content{
                margin-bottom: 35px;
            }
            .tweet-link{
                font-size: 1.2rem;
                a{
                    color: $cpa-main;
                    font-size: 1.2rem;
                    text-decoration: none;
                    i[class*="icon"]{
                        font-size: 2rem;
                        margin-left: 20px;
                    }
                }
                
            }
        }
        .more-news{
            .newsCard-container{
                display: flex;
                height: 170px;
                margin-bottom: 24px;
                .card-img{
                    width: 45%;
                }
                .card-content{
                    width: 55%;
                    padding: 52px 43px;
                }
            }
            
        }
    }
    
}
@media  (max-width: 425px){
    .news-conatiner{
        .more-container{
            .more-news{
                .newsCard-container{
                    display: block;
                    height: unset;
                    .card-img{
                        width: 100%;
                    }
                    .card-content{
                        width: 100%;
                    }
                }
            }
        }
    }
}

.customPaginationItem{
    transform: rotate(180deg);
}