.image-gallery-slide img{
  width: 70%;
}
.image-gallery-slide.center{
  text-align: center;
  background-color: rgba(0,0,0,0.7);
}

.attachments-carousel{
  width: 100%;
   height: 400px;
}