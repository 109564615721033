@import 'src/styles/variables.scss';
.serciceCard-container{
    position: relative;
    height: 603px;
    margin-bottom: 80px;
    
    .card-back-img{
        height: 100%;
        img{
            height: 603px;
            width: 100%;
            opacity: 0.15;
        }
    }
    .card-icon{
        position: absolute;
        top: 19px;
        right: 19px;
        img{
            border-radius: 50%;
        }
    }
    .card-content{
        position: absolute;
        bottom: 50px;
        text-align: right;
        padding: 0 26px;
        h4{
            color: $cpa-main;
        }
        p{
            font-size: 0.875rem;
        }
        button{
            height: 40px;
            line-height: 1;
            padding: 10px 20px;
            background: transparent;
            color: $cpa-main;
            cursor: pointer;
            border: 2px solid $cpa-main;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            &:hover{
                background-color: $cpa-main;
                color:#fff;
            }
        }
    }
    
 @media (max-width:768px) {
    margin-top: 15px;
    margin-bottom: 0;
 }
}
.custom-card button.custom-button{
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
  &:hover{
      color:$cpa-black;
      border: 2px solid $cpa-black;
  }
}
.custom-card{
    .card-back-img{
        height: 288px;
        img{
            height: 288px;
        }
    }
}

.moreBtnCard{
    text-align: left;
}