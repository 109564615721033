@import 'src/styles/variables.scss';
.about-row {
    display: flex;
    padding: 50px 0;
    // margin-top: 50px;
    background-color: #fff;
    .img-card{
        margin-left:5%;
        height: fit-content;
    }
    .icon-card{
        // margin-top: 20px;
        .card-icon{
           font-size: 3.375rem;
        }
        .card-head{
            font-size:1.5rem;
            margin-top: 18px;
            h3{
                color:$cpa-dark-blue;
                font-size: 1.125rem !important;
            }
        }
        .card-desc{
            font-size:  1rem;
            margin-top: 32px;
        }
    }
    
}
.about-row:nth-child(even) {
    background-color: unset;
    .back-color{
        background:#fff;
    }
}
.inline{
    display: inline;
}
@media  (max-width: 1024px)  {
    .about-row{
        .img-card{
            margin: auto;
        }
        .icon-card{
            text-align: center !important;

        }
    }
}

@media (max-width: 768px) {
    .about-row .icon-card{
        margin-top: 50px;
    }
    .about-row .col-12:last-of-type{
        margin-top: -55px;
    }
  }
@media (max-width: 425px) {
    .about-row .icon-card{
        margin-top: 0;
        padding: 50px;
    }
  }
