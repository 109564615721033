/* Importing */

@import "../fonts/fonts.css";
@import "../icon/css/fontello.css";
@import "./header.css";
@import "./footer.css";
@import "./bootstrap.min.css";
@import "./variables.scss";
@import "./LandingPage.scss";
@import "./sharing.css";
@import "./ltr.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
/* Styles */

// .cpa-content{
//   // background-color: red;
//  overflow: hidden;
//  display: block;
//  position: relative;
// }

.btn-next {
    color: #fff;
    background-color: $cpa-main;
    border-color: $cpa-main;
    &:hover {
        color: #fff;
        background-color: $cpa-main;
        border-color: $cpa-main;
    }
}

.errorMessage {
    color: #c14242;
}
.errorMessageEn {
    color: #c14242;
    text-align: left;
}

a {
    color: $cpa-defualt;
}
a:hover {
    text-decoration: none;
    color: $cpa-main;
}
b,
strong {
    font-weight: bold;
}
ul {
    list-style: none;
    padding: 0;
}
.ul1 {
    list-style: unset;
}
h2,
h3,
h4,
h5,
.h5 {
    text-transform: capitalize;
}

i {
    font-style: unset;
}
h3 {
    font-size: 1.5rem !important;
}
h4 {
    font-size: 1.25rem;
}
h5 {
    font-size: 1.125rem;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.bold {
    font-weight: bold;
}
.error {
    color: red;
    font-size: 1rem;
}
.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.font-md {
    font-size: 1rem;
}
.font-sm {
    font-size: 0.75rem;
}
.overlay {
    position: absolute;
    background-color: #00000054;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.underLined {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        bottom: -5px;
        height: 1px;
        width: 5%;
        background-color: $cpa-defualt;
    }
}
.blue-color {
    color: $cpa-dark-blue;
    a {
        color: $cpa-dark-blue;
    }
}
.green-color {
    color: $cpa-main;
    a {
        color: $cpa-main;
    }
}
.gray-color {
    color: #809696;
    a {
        color: #809696;
    }
}
.navbar-light .navbar-nav :hover {
    .nav-link {
        color: #42c194 !important;
    }
}
.toggle-button {
    i[class*="icon"] {
        font-size: 1.2rem;
    }
}
// .translate-icon{
//   // text-transform: uppercase;
// }
.dropdown-menu {
    text-align: right;
    right: -10px;
    .dropdown-item {
        &:hover {
            background: $cpa-main !important;
            color: #fff;
            a {
                color: #fff;
            }
        }
        &.active,
        &:active {
            color: #fff;
            background-color: $cpa-main;
        }
    }
}
.btn-success:disabled {
    opacity: 0.5 !important;
}
.nav-link,
.dropdown-item {
    opacity: 1 !important;
    text-transform: capitalize;
}
.cpa-main-link {
    .nav-link {
        font-size: 1rem;
        color: $cpa-main;
    }
}
.toggle-button {
    transform: rotateY(180deg);
}
.btn,
button {
    padding: 8px 35px 14px 35px;
    border-radius: 5px;
    &:focus,
    &:active {
        outline: 0;
        box-shadow: none;
    }
    @media (max-width: 768px) {
        padding: 5px 15px 5px 15px;
        font-size: 16px;
    }
    @media (max-width: 375px) {
        font-size: 12px;
    }
}
.green {
    background: $cpa-main;
    color: #fff;
    border: 2px solid $cpa-main;
    &:hover {
        color: $cpa-main;
        background: #fff;
    }
}
.default {
    background: $cpa-defualt;
    color: #fff;
    border: 2px solid $cpa-defualt;
    &:hover {
        color: $cpa-defualt;
        background: #fff;
    }
}
.btn:disabled {
    opacity: unset;
}

pre {
    overflow-x: auto;
    overflow: hidden;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    font-family: "Cairo-Regular", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.4em;
    color: $cpa-defualt;
}

.cpa-body {
    font-family: "Cairo-Regular", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.4em;
    color: $cpa-defualt;
    min-width: 230px;
    max-width: 100%;
    margin: 0 auto;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    // font-smoothing: antialiased;
    font-weight: 300;
    line-height: 30px;
    overflow-x: hidden;
    text-align: right;
    background-color: #f8f9fa;
    .cpa-hidden {
        display: none !important;
    }
}

.form-input {
    color: #bbbbbd;
    position: relative;
    margin-bottom: 20px;

    i[class*="icon"] {
        position: absolute;
        top: 2px;
        right: 8px;
        color: #c1c1c1;
        font-size: 1.2rem;
    }
    input,
    select,
    textarea {
        width: 100%;
        color: $cpa-dark-blue;
        border-color: #eaeaea;
        border-width: 1px;
        border-radius: 10px;
        border-style: solid;
        text-align: right;
        padding-right: 40px;
        outline: none;
    }
    select {
        padding-bottom: 3px;
    }
}
.file-input {
    position: relative;
    cursor: pointer;
    input {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        cursor: pointer;
    }
    .custom-input-file {
        height: 35px;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        padding-right: 40px;
        text-align: right;
        cursor: pointer;
    }
}
::-webkit-input-placeholder {
    color: #bbbbbd;
}
.modal.show {
    .quiz-section {
        .modal-content {
            @media (max-width: 575px) {
                width: 135%;
            }
        }
    }
}
.modal-header {
    border-bottom: 0 !important;
    position: absolute;
    z-index: 5;
    top: 2%;
    right: 2%;

    .close {
        margin: 0 !important;
        padding: 12px !important;
        color: $cpa-dark-blue;
        border: 1px solid $cpa-dark-blue;
        background: #fff;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        opacity: 1;
        span:first-of-type {
            position: relative;
            top: -10px;
            left: -4px;
        }
        @media (max-width: 768px) {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            opacity: 1;
            display: flex;
            // justify-content: center;
            // align-items: center;
            // line-height: 0;
        }
    }

    .close:hover {
        color: #fff;
        background: $cpa-dark-blue;
        opacity: 1;
    }
}
.customHeader-container {
    .dropdown:hover .dropdown-menu {
        display: block;
    }
}
.share-control {
    margin-bottom: 25px;
    button {
        padding: 5px;
        width: 45px;
        height: 45px;
        margin-right: 15px;
        transition: width 0.75s ease-in-out;
        overflow: hidden;
    }
}
.submit-cpa-btn {
    background-color: $cpa-main;
    color: #fff;
    border: 1px solid $cpa-main;
    &:hover {
        background-color: #fff;
        color: $cpa-main;
    }
}
.secendary-cpa-btn {
    background-color: $cpa-dark-blue;
    color: #fff;
    border: 1px solid $cpa-dark-blue;
    &:hover {
        background-color: #fff;
        color: $cpa-dark-blue;
    }
}
.height-auto {
    height: auto !important;
    overflow: unset !important;
}
.display-none {
    display: none !important;
}
.cpa-tooltip {
    position: relative;
    h3,
    h4,
    h5 {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
.cpa-divider {
    height: 2px;
    width: 80%;
    margin: 35px auto;
    background: rgb(230, 230, 230);
    border-radius: 50%;
}
.cpa-tooltip .cpa-tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #527c9a;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 35px;
}

.cpa-tooltip:hover .cpa-tooltiptext {
    visibility: visible;
}
/****************flip card **************/

.flip-card {
    background-color: transparent;
    width: 100%;
    height: 300px;
    perspective: 1000px;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    margin: 35px 0;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.flip-card-front {
    background-color: #bbb;
    color: black;
    z-index: 2;
    img {
        width: 100%;
        height: 100%;
    }
}

.flip-card-back {
    background-color: #2980b9;
    color: white;
    transform: rotateY(180deg);
    z-index: 1;
    position: relative;
    img {
        width: 100%;
        height: 100%;
    }
}
.title-back {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #42c194b3;
    padding: 35% 10px;
    h4 {
        color: #fff;
        font-weight: bold;
    }
}
.required {
    position: relative;
    &:after {
        content: "*";
        color: red;
        position: absolute;
        left: -15px;
        top: 0;
        font-size: 1.5rem;
    }
}
.scum {
    img {
        margin-bottom: 16px; //Need to control height
    }
}
.collapse-head {
    padding: 25px 15px;
    position: relative;
    margin: 25px 0;

    &[aria-expanded="true"] {
        &::after {
            transform: rotate(180deg);
            transition: 0.75s all ease-in-out;
        }
    }
    &::after {
        position: absolute;
        top: 25px;
        left: 35px;
        vertical-align: 0rem;
        content: "\F004";
        font-family: "fontello";
        transition: 0.75s all ease-in-out;
    }
}
.dot {
    &:after {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $cpa-main;
        right: -25px;
        top: 10px;
    }
}
.filter {
    padding: 5px 0;
    text-align: center;
    select {
        padding: 5px;
        margin: auto;
        border-radius: 5px;
        border: 1px solid #efefef;
        width: 250px;
        outline: 0;
        color: $cpa-gray;
        option {
            padding: 5px;
        }
    }
}
.notFound-container {
    word-wrap: break-word;
    width: 100%;
    .notFound-content {
        background-color: #fff;
        padding: 25px;
        border-radius: 10px;
        width: 100%;
        margin: auto;
        margin: 55px auto 25px;
        .inner {
            padding: 25px;
            border: 2px solid $cpa-main;
            border-radius: 10px;
            margin: auto;

            color: $cpa-defualt;
            h3 {
                color: $cpa-dark-blue;
            }
        }
    }
}
.user-wrapper {
    a {
        color: inherit;
        &:hover {
            color: $cpa-main;
        }
    }
    font-weight: bold;
}
.members-modal,
.compare-container {
    direction: rtl;
}
.separator {
    height: 50px;
    background-color: #f8f9fa;
}
.container-card .modal-body {
    position: relative;
    .share-control {
        position: absolute;
        right: 0;
        left: 0;
        // bottom: -66px;
        background: #fff;
        padding: 2px 2px;
        margin: 1px;
        border-radius: 15px;
        direction: rtl;
        text-align: right;
    }
}
.error_msg {
    color: red;
}
.subMenu {
    padding: 0 10px;
}
@media (max-width: 768px) {
    .services-container .container {
        height: auto;
    }
    .notFound-container {
        width: 100%;
        .notFound-content {
            padding: 5px;
            border-radius: 10px;
            width: 100%;
            // margin: 55px auto 25px;
            .inner {
                padding: 5px;
                border: 2px solid $cpa-main;
            }
        }
    }
}
@media (max-width: 425px) {
    .breadCrumb-container {
        height: auto;
    }
    .notFound-container {
        width: 100%;
        .notFound-content {
            padding: 2px;
            border-radius: 10px;
            width: 100%;
            margin: 55px auto;
            .inner {
                padding: 2px;
                border: 2px solid $cpa-main;
            }
        }
    }
}
@media (max-width: 575px) {
    .breadCrumb-container {
        height: auto;
    }
}
@media (max-width: 575px) {
    .carousel-caption h2 {
        font-size: 1rem;
    }

    .serciceCard-container.main {
        height: 425px;
        overflow: hidden;
    }
}
@media (max-width: 1024px) {
    .adPopUp-conatiner .cpa-ads-content .cpa-ads-header {
        padding: 10px 0 10px 0;
        margin: auto 70%;
        width: 40px;
        i[class*="icon"] {
            font-size: 1.25rem;
        }
    }
    .breadCrumb-container {
        height: auto;
    }
    .img-header .img-container img {
        width: unset;
    }
    // .header-wrapper .navbar-brand img{
    //   width:130%;
    // }
}

@media (max-width: 1025px) and (min-width: 992) {
    .post-wrapper .card .card-body .newsletter-icon {
        bottom: 44%;
    }
    .post-wrapper .card .card-body .submit-btn button,
    .post-wrapper .card .card-body .card-link {
        margin-top: 0px;
    }
    .breadCrumb-container {
        height: auto;
    }
}
@media (max-width: 991px) {
    .navbar-content-main .beta_span {
        // right: 10em;
        right: 3em;
        // height: 90px;
        height: 35px;
    }
    .navbar-content-main .beta_span span {
        font-size: 0.7rem;
    }
}
@media (max-width: 1024) {
    .homePage-container .top-head {
        height: auto;
    }
}
@media (min-width: 1920px) {
    .homePage-container .embed-responsive .embed-responsive-item {
        width: 100%;
    }
}

.body {
    min-height: 100vh;
}

// .breadCrumb-container,.img-header,header,.side-drawer,.post-wrapper,footer{
//   display: none;
// }
