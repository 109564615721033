.annualReports-container {
    .image-gallery-content {
        top: 10vh;
    }
}
.annualReports-container {
    .shareOn {
        position: relative;
        top: -3.5rem;
    }
    .modal-content {
        background-color: transparent !important;
        border: unset !important;
        .container-card {
            width: 100%;
            background: transparent;
            box-shadow: unset;
            .inner {
                border: unset;
                padding: unset;
                .modal-header {
                    .close {
                        position: absolute;
                        right: -10px;
                        top: -10px;
                    }
                }
                .modal-body {
                    // width: 100%;
                    height: auto;

                    .img-body {
                        // height: 100%;

                        img {
                            width: 100%;
                            // height: 100%;
                            object-fit: fill;
                        }
                    }
                }
            }
        }
    }
}
