@import 'src/styles/variables.scss';
.board-container {
    color: $cpa-defualt;
    text-align: right;
    .board-title{
        // color:  $cpa-dark-blue;
        color: #42c194;
        margin: 35px 0;
        font-size: 1.375rem;
    }
    .landing-row{
        margin-top: 0;
        // padding: 5%;
        i[class*="icon"]{
            margin-bottom: 30px;
        }
        li{
            margin-bottom: 20px;
        }
    }

    .board-header {
        h4 {
            color: $cpa-dark-blue;
            font-size: 1.6875rem;
            margin-bottom: 17px;
        }

        p {
            margin-bottom: 62px;
            font-size: 1.0625rem;
        }
    }
    .landing-card{
        min-height: 95%;
        h3{
            color:$cpa-dark-blue;
        }
    }
    .baord-content {
        .baord-card {
            padding: 45px 51px;
            text-align: center;
            max-width: 372px;
            margin: 20px auto;
            // padding: 10%;
            background: #fff;
            margin-bottom: 25px;
            box-shadow: 0 1px 4px #809696 !important;
            border-radius: 10px;

            i {
                color: $cpa-main;
                margin-bottom: 8px;
                font-size: 1.875rem;
            }

            .icon-Observation {
                margin-left: 25px;
            }

            h5 {
                font-size: 1rem;
                margin-bottom: 18px;
            }

            p {
                color: $cpa-gray;
                margin-bottom: 32px;
            }
        }
    }
}