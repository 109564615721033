@import 'src/styles/variables.scss';

.motamaken-container{
  .motamaken-landing{
    p{
      margin: 20px 0 0;
      color: $cpa-dark-blue;
      font-size: 1.5rem;
    }
  }
  .newsCard-container{
    background: #fff
  }
}

.notFound-content{
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
  width: 80%;
  margin: 55px auto 25px;
  .inner{
    padding: 25px;
    border:2px solid $cpa-main;
    border-radius: 10px;
    color:$cpa-defualt;
    h3{
      color:$cpa-dark-blue
    }
  }
}
