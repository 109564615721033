@import 'src/styles/variables.scss';

.consumer-consultant-container{
  font-family: 'Cairo-Regular';
  .icon-card .card-desc{
    height: auto;
  }
  h3{
      font-size: 1.5rem;
      color:$cpa-dark-blue;
      margin-bottom: 30px;
      i[class*="icon"]{
          margin-left: 5px;
          color:$cpa-main;
      }
  }
  .icon-card{
    margin-top: 7%;
  }
  .videos-container{
    &-less{
      height: 53rem;
      overflow: hidden;
      transition: 1s ease-in-out all;
    }
    &-more{
      height:110rem;
      transition: 1s ease-in-out all;
    }
    
  }
  .consultant-wrapper{
    display: flex;
    padding: 70px 0;
    h3{
      color: $cpa-dark-blue;
    }
  }
  .goals-wrapper{
    h4{
      color: $cpa-dark-blue;
      font-size: 1.5rem;
      margin: 1.8rem 0;
    }
    ul{
      li{
        font-size: 1rem;
        color: $cpa-black;
      }
    }
    p{
      font-size: 1rem;
    }


  }

}

.home-section{
  margin-bottom: 0 !important;
}
.episode-container{
  .newsCard-container{
    overflow: unset;
    background: #fff;
  }
  .card-content{
    p{
      display: none;
    }
  }
}
@media (max-width: 768px) {
  .consumer-consultant-container{
    .icon-card{
      margin-top: 0%;
    }
  } 
}