@import "src/styles/variables.scss";
.container-id-card {
    font-family: "Cairo-Regular";

    .card-img {
        // max-width: 184px;
        // height: 250px;
        margin: auto;
        img {
            max-width: 100%;
            // height: 100%;
            border-radius: 10px;
            height: auto;
        }
    }
    .card-content {
        text-align: right;
        padding-top: 45px;
        h4 {
            color: $cpa-main;
            font-size: 1.125rem;
            margin-bottom: 14px;
        }
        p {
            color: $cpa-gray;
            font-size: 0.875rem;
            margin-bottom: 16px;
        }
        .card-links {
            text-align: right;
            a {
                font-size: 1.75rem;
            }
            span {
                font-size: 1.125rem;
                position: relative;
                top: -5px;
            }
        }
    }
    .card-desc {
        p {
            margin-top: 33px;
            text-align: right;
            color: $cpa-defualt;
            font-size: 1.125rem;
            padding: 0;
            line-height: 42px;
        }
    }
}
.hide-desc {
    display: none !important;
}
