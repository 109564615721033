@import "src/styles/variables.scss";
.shakwa-row {
    margin-top: 3rem;
    padding: 0;
    background: #ffffff url("https://cpa.org.sa:443/Images/Rectangle.png")
        no-repeat 100% 100%;
    background-size: cover;
    border-radius: 5px;
    font-family: "Cairo-Regular";
    .shakwa-img {
        justify-content: center;
        display: flex;
        width: 100%;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .shakwa-card {
        border: none;
        text-align: center;
        font-family: "Cairo-Regular";
        border: none;
        background-color: unset;
        box-shadow: unset !important;
        color: #fff;
        .card-title {
            color: #fff;
            font-size: 1.625rem;
            margin-bottom: 1.5rem;
        }
        .card-text {
            font-size: 1rem;
            text-align: center;
            line-height: 1.5;
            width: 80%;
            margin: 0 auto 2rem;
        }
        .card-link {
            border: 2px solid #fff;
            color: #fff;
            margin-top: 20px;
            &:hover {
                border: 2px solid $cpa-black;
                color: $cpa-black;
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
            }
        }
    }
}
.quiz-section {
    max-width: 70% !important;
    .modal-body {
        padding: 63px;
        .quiz-header {
            width: 100%;
            margin: 1rem 0;
            text-align: center;
            h4 {
                font-size: 1.75rem;
                color: $cpa-dark-blue;
                font-weight: bold;
                margin: 0 0 20px;
            }
            p {
                font-size: 1rem;
                color: $cpa-black;
            }
        }
        .quiz-content {
            width: 100%;
            padding: 35px;
            border: 1px solid #e4edf0;
            margin: 30px 0;
            color: $cpa-black;
            text-align: right;
            p {
                font-size: 1rem;
                font-weight: bold;
                height: 80px;
                margin-bottom: 0;
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
            }
            .quiz-answers {
                .progress-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .progress-section {
                        width: 95px;
                        height: 95px;
                        display: block;
                        position: relative;
                        margin: 0 auto;
                        span {
                            position: absolute;
                            top: 35%;
                            text-align: center;
                            left: 0;
                            right: 0;
                            font-size: 1.5rem;
                            color: $cpa-black;
                        }
                    }
                }

                .form-check {
                    label {
                        display: block;
                        position: relative;
                        font-weight: 300;
                        font-size: 1em;
                        padding: 0 25px 0 0;
                        margin: 5px auto;
                        z-index: 9;
                        cursor: pointer;
                        -webkit-transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;

                        input[type="radio"] {
                            position: absolute;
                            visibility: hidden;
                        }
                    }
                    .check {
                        display: block;
                        position: absolute;
                        border: 2px solid #8d8e8e;
                        border-radius: 50%;
                        height: 17px;
                        width: 17px;
                        top: 8px;
                        right: 0;
                        -webkit-transition: all 0.4s ease-in-out;
                        -moz-transition: all 0.4s ease-in-out;
                        -o-transition: all 0.4s ease-in-out;
                        transition: all 0.4s ease-in-out;
                        &:after {
                            display: block;
                            position: absolute;
                            content: "";
                            border: 2px solid #fff;
                            border-radius: 50%;
                            height: 13px;
                            width: 13px;
                            top: 0;
                            right: 0;
                            -webkit-transition: all 0.4s ease-in-out;
                            -moz-transition: all 0.4s ease-in-out;
                            -o-transition: all 0.4s ease-in-out;
                            transition: all 0.4s ease-in-out;
                        }
                    }
                }
            }
        }
        .form-check {
            position: relative;
            .ans-icon {
                position: absolute;
                top: 5px;
                left: 5px;
            }
        }
        .ans-icon {
            display: none;
            .icon-ok,
            .icon-cancel {
                display: none;
            }
        }
        .rightAns {
            color: green;
            .ans-icon {
                display: block;
                .icon-ok {
                    display: block;
                }
            }
        }
        .wrongAns {
            color: red;
            .ans-icon {
                display: block;
                .icon-cancel {
                    display: block;
                }
            }
        }
        .quiz-button {
            text-align: center;
            button {
                background-color: $cpa-main !important;
                cursor: pointer;
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
                &:focus,
                &:active {
                    outline: none;
                }
            }
        }
        @media (max-width: 768px) {
            padding: 15px;
        }
    }
}

.result-view {
    h3 {
        margin: 25px 0;
        color: $cpa-main;
    }
    p,
    span {
        font-size: 3rem;
        margin-bottom: 55px;
    }
}

input[type="radio"]:checked ~ .check {
    border: 2px solid $cpa-main !important;
}

input[type="radio"]:checked ~ .check::after {
    background-color: $cpa-main;
}
/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    .quiz-section {
        max-width: 90% !important;
        .modal-body {
            .quiz-header {
                width: 100%;
                margin: 0.7rem 0;
                text-align: center;
                h4 {
                    font-size: 1rem;
                    color: $cpa-dark-blue;
                    font-weight: bold;
                    margin: 0 0 20px;
                }
                p {
                    font-size: 0.75rem;
                    color: $cpa-black;
                }
            }
            .quiz-content {
                width: 100%;
                padding: 15px;
                border: 1px solid #e4edf0;
                margin: 15px 0;
                color: $cpa-black;
                text-align: right;
                p {
                    font-size: 0.7rem;
                    font-weight: bold;
                    height: auto;
                    margin-bottom: 0;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                }
                .quiz-answers {
                    .progress-wrapper {
                        display: none;
                    }

                    .form-check {
                        label {
                            display: block;
                            position: relative;
                            font-weight: 300;
                            font-size: 0.7rem;
                            padding: 0 25px 0 0;
                            margin: 5px auto;
                            z-index: 9;
                            cursor: pointer;
                            -webkit-transition: all 0.3s ease-in-out;
                            -moz-transition: all 0.3s ease-in-out;
                            -o-transition: all 0.3s ease-in-out;
                            transition: all 0.3s ease-in-out;

                            input[type="radio"] {
                                position: absolute;
                                visibility: hidden;
                            }
                        }
                        .check {
                            display: block;
                            position: absolute;
                            border: 2px solid #8d8e8e;
                            border-radius: 50%;
                            height: 17px;
                            width: 17px;
                            top: 8px;
                            right: 0;
                            -webkit-transition: all 0.4s ease-in-out;
                            -moz-transition: all 0.4s ease-in-out;
                            -o-transition: all 0.4s ease-in-out;
                            transition: all 0.4s ease-in-out;
                            &:after {
                                display: block;
                                position: absolute;
                                content: "";
                                border: 2px solid #fff;
                                border-radius: 50%;
                                height: 13px;
                                width: 13px;
                                top: 0;
                                right: 0;
                                -webkit-transition: all 0.4s ease-in-out;
                                -moz-transition: all 0.4s ease-in-out;
                                -o-transition: all 0.4s ease-in-out;
                                transition: all 0.4s ease-in-out;
                            }
                        }
                    }
                }
            }
            .form-check {
                position: relative;
                .ans-icon {
                    position: absolute;
                    top: 5px;
                    left: 5px;
                }
            }
            .ans-icon {
                display: none;
                .icon-ok,
                .icon-cancel {
                    display: none;
                }
            }
            .rightAns {
                color: green;
                .ans-icon {
                    display: block;
                    .icon-ok {
                        display: block;
                    }
                }
            }
            .wrongAns {
                color: red;
                .ans-icon {
                    display: block;
                    .icon-cancel {
                        display: block;
                    }
                }
            }
            .quiz-button {
                text-align: center;
                button {
                    background-color: $cpa-main !important;
                    cursor: pointer;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                    &:focus,
                    &:active {
                        outline: none;
                    }
                }
            }
        }
    }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .quiz-section {
        max-width: 90% !important;
        .modal-body {
            .quiz-header {
                width: 100%;
                margin: 0.7rem 0;
                text-align: center;
                h4 {
                    font-size: 1rem;
                    color: $cpa-dark-blue;
                    font-weight: bold;
                    margin: 0 0 20px;
                }
                p {
                    font-size: 0.75rem;
                    color: $cpa-black;
                }
            }
            .quiz-content {
                width: 100%;
                padding: 15px;
                border: 1px solid #e4edf0;
                margin: 15px 0;
                color: $cpa-black;
                text-align: right;
                p {
                    font-size: 0.7rem;
                    font-weight: bold;
                    height: auto;
                    margin-bottom: 0;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                }
                .quiz-answers {
                    .progress-wrapper {
                        display: none;
                    }

                    .form-check {
                        label {
                            display: block;
                            position: relative;
                            font-weight: 300;
                            font-size: 0.7rem;
                            padding: 0 25px 0 0;
                            margin: 5px auto;
                            z-index: 9;
                            cursor: pointer;
                            -webkit-transition: all 0.3s ease-in-out;
                            -moz-transition: all 0.3s ease-in-out;
                            -o-transition: all 0.3s ease-in-out;
                            transition: all 0.3s ease-in-out;

                            input[type="radio"] {
                                position: absolute;
                                visibility: hidden;
                            }
                        }
                        .check {
                            display: block;
                            position: absolute;
                            border: 2px solid #8d8e8e;
                            border-radius: 50%;
                            height: 17px;
                            width: 17px;
                            top: 8px;
                            right: 0;
                            -webkit-transition: all 0.4s ease-in-out;
                            -moz-transition: all 0.4s ease-in-out;
                            -o-transition: all 0.4s ease-in-out;
                            transition: all 0.4s ease-in-out;
                            &:after {
                                display: block;
                                position: absolute;
                                content: "";
                                border: 2px solid #fff;
                                border-radius: 50%;
                                height: 13px;
                                width: 13px;
                                top: 0;
                                right: 0;
                                -webkit-transition: all 0.4s ease-in-out;
                                -moz-transition: all 0.4s ease-in-out;
                                -o-transition: all 0.4s ease-in-out;
                                transition: all 0.4s ease-in-out;
                            }
                        }
                    }
                }
            }
            .form-check {
                position: relative;
                .ans-icon {
                    position: absolute;
                    top: 5px;
                    left: 5px;
                }
            }
            .ans-icon {
                display: none;
                .icon-ok,
                .icon-cancel {
                    display: none;
                }
            }
            .rightAns {
                color: green;
                .ans-icon {
                    display: block;
                    .icon-ok {
                        display: block;
                    }
                }
            }
            .wrongAns {
                color: red;
                .ans-icon {
                    display: block;
                    .icon-cancel {
                        display: block;
                    }
                }
            }
            .quiz-button {
                text-align: center;
                button {
                    background-color: $cpa-main !important;
                    cursor: pointer;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                    &:focus,
                    &:active {
                        outline: none;
                    }
                }
            }
        }
    }
}
