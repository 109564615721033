.compare-landing-container {
    .landing-row {
        overflow: auto;
        white-space: nowrap;
        overflow-y: hidden;
        // margin-right: 8rem;
        // margin-left: 8rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        span.active {
            display: inline-block;
            text-align: center;
            padding: 0.5rem;
            background: #fff;
            margin-bottom: 25px;
            box-shadow: 0 1px 4px #809696;
            border-radius: 10px;
            // padding: 2%;
            cursor: pointer;
            margin-right: 1rem;
            background-color: white;
            color: #6aceaa;
        }
        span {
            display: inline-block;
            text-align: center;
            padding: 0.5rem;
            background: #fff;
            margin-bottom: 25px;
            box-shadow: 0 1px 4px #809696;
            border-radius: 10px;
            // padding: 2%;
            cursor: pointer;
            margin-right: 1rem;
        }
    }
}
.generalTips-container {
    // .carousel-inner{
    //     height: 25rem;
    // }
    .share-link {
        cursor: pointer;
    }
    .share-control {
        // display: none;
        &.active {
            // display: block;
            background-color: transparent;
        }
    }
    // .active{
    //     // background-color: #6ACEAA;
    //     // color:#6ACEAA;
    // }
    h1 {
        font-size: 1.5rem;
        color: #215c80;
    }

    .generalTips {
        padding-bottom: 3rem;
        margin-top: 3rem;

        .sideMenu {
            background: white;
            padding: 10px;
            box-shadow: 0px 0px 10px -2px #707070;
            border-radius: 10px;
            label {
                color: #215c80;
                width: 100%;
            }
            .sideMenu-label {
                color: #a6a8a7;
            }
            .btnContent {
                display: flex;
                i {
                    color: #215c80;
                }
            }
            .btn-menu {
                padding: unset;
                width: 100%;
            }
            .btn-subMenu {
                color: #6be2b7;
                padding: 0;
            }
        }
        span {
            font-size: 1.375rem;
        }
        p {
            padding-top: 1rem;
        }
    }
    .TopMenu {
        text-align: center;
        margin-top: 3.2rem;
        margin-bottom: 25px;
        button {
            margin: 5px;
            &.active {
                background-color: white;
                color: #42c194;
            }
        }
    }

    .generalInst {
        color: #6be2b7;
    }
    .howToContainer {
        span {
            color: #6be2b7;
            font-size: 1.5rem;
        }
        p {
            padding-top: 1rem;
        }
    }

    .generalTips-card {
        width: 80%;
        margin-top: 2rem;
        margin-bottom: 2rem;
        background-color: white;
        padding: 1rem;
        .cardImage {
            width: 15rem;
        }
        .cardText {
            padding-right: 1rem;
            .cardText-header {
                color: #6be2b7;
                direction: rtl;
                font-size: 1.5rem;
            }
            .cardText-btn {
                color: #6be2b7;
                border: none;
                background-color: transparent;
            }
        }
    }

    .videoCard-container {
        width: 19rem;
    }

    .videoTitle {
        color: #6be2b7;
        margin-bottom: 1.5rem;
    }
    .generalTips-videos {
        // width: 50%;
        align-items: center;
        justify-content: center;
        margin-bottom: 5rem;
        .carousel-control-prev {
            top: 40%;
            height: 10%;
        }
        .carousel-control-next {
            top: 40%;
            height: 10%;
        }
        .carousel-item {
            // display: flex;
            justify-content: center;
            img {
                display: inline-block;
                height: 300px;

                object-fit: fill;
                // width: 250px !important;
            }
        }
    }
}
@media (max-width: 425px) {
    .generalTips-container {
        .generalTips-videos {
            .carousel-control-next {
                margin-right: 2rem;
            }
            .carousel-control-prev {
                margin-left: 2rem;
            }
        }
        .generalInst {
            margin-top: 2rem;
        }
    }
    .compare-landing-container {
        .landing-row {
            white-space: unset;
        }
    }
}
