.comapreAndRate-container {
    .over-lay {
        background: #00000025;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 999999;
        color: #fff;
        text-align: center;
        font-size: 3.25rem;
        padding: 20% 0;
    }
    .active {
        background-color: white;
        color: #6aceaa;
    }
    h1 {
        font-size: 1.5rem;
        color: #215c80;
    }
    .container {
        padding-bottom: 2rem;
        padding-top: 2rem;
    }
}

.customCard {
    // margin-left: 5px;
    // margin-right: 5px;
    // margin-bottom: 25px;
    box-shadow: 0px 0px 15px -2px #707070;
    text-align: center;
    padding: 30px;
    height: 300px;
    min-height: 300px;
    border-radius: 10px;
    margin-bottom: 1.5rem;
    background-color: white;
    img {
        margin-top: 50px;
        border: none;
        background: #fff;
        width: fit-content;
        padding: 10px;
        max-width: 125px;
        max-height: 75px;
        border-radius: 15px;
    }

    p {
        position: relative;
        font-size: 2rem;
        line-height: 3rem;
        // top: 80px;
        color: #215c80;
    }
    .testMargin {
        margin: 10px;
    }
    .customCardContent {
        display: none;
    }
}

.customCard:hover {
    box-shadow: 0px 0px 15px -2px #707070;
    text-align: center;
    padding: 30px;
    height: auto;
    background: #6be2b7;
    border-radius: 10px;
    img {
        display: none;
        border: none;
        background: #fff;
        width: fit-content;
        padding: 10px;
        max-width: 125px;
        border-radius: 15px;
    }

    p {
        position: relative;
        font-size: 2rem;
        line-height: 3rem;
        top: 50px;
        color: white;
    }
    .testMargin {
        margin: 10px;
    }
    .customCardContent {
        display: block;
        .btnRow {
            justify-content: center;
            .innerBtn {
                background: white;
                border-radius: 5px;
                padding: 10px;
                margin: 0 5px 5px 0;
            }
            margin-top: 4rem;
            // background: white;
            // border-radius: 5px;
            // a{
            //     padding: 5px 25px 5px 25px;
            // }
        }
    }
}

.creditCardContainer {
    box-shadow: 0px 0px 10px -2px #707070;
    padding: 30px;
    border-radius: 10px;
    background-color: white;
    margin-bottom: 1.5rem;
    // cursor: pointer;
    h2 {
        color: #6be2b7;
        font-size: 1.5rem;
        cursor: pointer;
    }
    .btn {
        width: 100%;
    }
}

.compareBtn {
    display: -webkit-box !important;
    flex-wrap: nowrap !important;
    .btnLabel {
        display: block;
    }
}

.pageContent {
    .TopMenu {
        text-align: center;
        margin-bottom: 25px;
        button {
            margin: 5px;
        }
    }
}

.sideMenu {
    background: white;
    padding: 10px;
    // height: 80%;
    box-shadow: 0px 0px 10px -2px #707070;
    border-radius: 10px;
    label {
        // color: #6BE2B7;
        color: #215c80;
        width: 100%;
    }
    .sideMenu-label {
        color: #a6a8a7;
    }
    .btnContent {
        display: flex;
        i {
            color: #215c80;
            // position: absolute;
            // left: 1rem;
        }
    }
    .btn-menu {
        padding: unset;
        width: 100%;
        // border: 1px solid black;
    }
    .btn-subMenu {
        color: #6be2b7;
        padding: 0;
    }
}

.compareContent {
    .pageTitle {
        font-size: 2rem;
        color: #215c80;
    }
    .filtersContainer {
        text-align: center;
        display: contents;
        input.chk-btn {
            display: none;
        }
        input.chk-btn + label {
            width: 90%;
            box-shadow: 0 0 5px 1px #0000001f;
            font-size: 1rem;
            background: white;
            padding: 5px 8px;
            cursor: pointer;
            border-radius: 5px;
            color: #6be2b7;
            margin: 0px 25px 15px 25px;
        }
        input.chk-btn:not(:checked) + label:hover {
            //box-shadow: 0px 1px 3px;
            background: #6be2b7;
            color: white;
            // border: 1px solid white;
        }
        input.chk-btn + label:active,
        input.chk-btn:checked + label {
            //box-shadow: 0px 0px 3px inset;
            background: #6be2b7;
            color: white;
            // border: 1px solid white;
        }
    }
}

.tableTitle {
    .addToComparision {
        vertical-align: middle;
        button {
            border-radius: 50%;
            padding: 12px 20px 12px 20px;
        }
        .btn-primary.disabled,
        .btn-primary:disabled {
            background: #6be2b7;
            border: unset;
            opacity: 0.5;
        }
        .btn-primary.disabled:hover,
        .btn-primary:disabled:hover {
            background: #6be2b7;
            border: unset;
            opacity: 0.5;
            color: white;
        }
    }
    th {
        text-align: center;
    }
    .removeFromComparison {
        text-align: left;
        button {
            border-radius: 50%;
            padding: 12px 20px 12px 20px;
        }
    }
}

.comparisonModal {
    direction: rtl;
    margin-top: 90px;
    padding-left: 25px;
    padding-right: 25px;
    h4 {
        text-align: right;
    }
    .comparisonCard {
        text-align: center;
        color: #6be2b7;
        .comparisonCardContent {
            padding: 10px;
            box-shadow: 0px 0px 10px -2px gray;
            border-radius: 10%;
        }
    }
}

@media (max-width: 425px) {
    .creditCardContainer {
        .btn {
            width: 25%;
            padding-right: 20px;
        }
    }
}
