.cpa-select{
    
    .input-select{
        position: relative;
        color: #bbbbbd;
        
        i[class*="icon"] {
            position: absolute;
            top: 2px;
            
            color: #c1c1c1;
            font-size: 1.2rem;
        }
        .icon-menu-media{
            right: 8px;
        }
        input{
            cursor: pointer;
            width: 100%;
            color: #bbbbbd;
            border-color: #eaeaea;
            border-width: 1px;
            border-radius: 10px;
            border-style: solid;
            padding-right: 40px;
            background: #fff;
            opacity: 1;
        }
        .icon-down-open{
            left:  15px ;
            transform: rotate(0deg);
            transition: 0.25s transform ease-in;
        }
    }
    .select-options{
        position: absolute;
        top: 35px;
        height: 0;
        transition: height 1s  ease-in;
        overflow: hidden;
        width: 90%;
        color:#000;
        background: #fff;
        border-radius: 5px;
        z-index: 1;
        
        .option{   
            cursor: pointer;
            &:not(:last-of-type){
                border-bottom: 1px solid #eaeaea;
            }
        }
    }
    
}
.open{
    .select-options{
        border:1px solid #eaeaea;
        padding: 10px 35px;
        height: fit-content;
        transition: height 0.25s  ease-out;
        .option{ 
            margin-bottom: 5px;
            padding-bottom: 5px;
        }
    } 
    .icon-down-open{
        transform: rotate(180deg) !important;
        transition: 0.25s transform ease-out !important;
    }
}