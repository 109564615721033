@import 'src/styles/variables.scss';
.videoCard-container{
    margin-bottom: 25px;
    height: 93%;
    .card-video{
        width: 100%;
    }
    .card-content{
        line-height: 30px;
        color:$cpa-defualt;
        padding: 15px;
        h4{
            font-size: 1rem;
            color: $cpa-main;
        }
        p{
            font-size: 0.75rem;
        }
        i[class*="icon"]{
            color:$cpa-dark-blue;
            margin-left: 5px;
            font-size: 1.2rem;
        }
        .icon-Observation{
            margin-left: 15px;
        }
    }
}