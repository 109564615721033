@import 'src/styles/variables.scss';

.Organize-container{
    line-height: 30px;
    font-family:'Cairo-Regular';
    
    strong{
        font-weight: bold;
        margin-left: 5px;
    }
    h3{
        color: $cpa-dark-blue;
        margin: 35px 0;
    }
    h4{
        color:$cpa-dark-blue;
    }
    h5{
        opacity: 0.7;
    }
    p{
        font-size: 1rem;
    }
    .container{
        margin-top: 55px;
    }
    .collapse-head{
        padding: 25px 15px 25px 70px;
    }
    .collapse-content{
        margin-bottom: 35px;
    }
    .cpa-divider{
        height: 0;
    }
}