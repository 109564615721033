@import 'src/styles/variables.scss';
#indicativeContracts-container{
    .flex-row:first-of-type{
        margin-top: 0;
        margin-bottom: 55px;
    }
    .nav-item{
        margin-bottom: 25px;
        a{
            opacity: 1 !important;
            position: relative;
            color:$cpa-defualt;
            font-size: 1.25rem;
            span{
                padding-bottom: 15px;
                line-height: 3;
            }
            &:hover{
                font-weight: bold;
                color:$cpa-main;
                span{
                    border-bottom: 2px solid $cpa-main
                }
            }
        }
        
        .active{
            background-color: unset !important;
            color:$cpa-main !important;
            font-weight: bold;
            span{
                border-bottom: 2px solid $cpa-main
            }
        }
    }
    .tab-pane{
        h4{
            color:$cpa-dark-blue;
            margin: 25px 0;
        }
        p{
            font-size: 1rem;
        }
        .end-one{
            color:#EA2E2E;
            font-size: 0.75rem;
            margin-bottom: 35px;
        }
        
    }
}