@import 'src/styles/variables.scss';
.services-container{
    margin-bottom: 81px;
    padding-top: 50px;

    .services-head{
      margin-bottom: 50px;
      text-align: center;
      h4{
          color:$cpa-dark-blue;
          font-size: 1.5rem;
      }
      p{
         color: $cpa-defualt;
         font-size: 1rem;
      }
      @media (max-width:768px) {
        margin-bottom: 20px;
      }
    }
    .container{
        height: 603px;
        .row{
            height: 100%;
            .col-12{
                height: 100%;
            }
        }
    }
    .custom-row{
        display: flex;
        flex-direction: column;
        .custom-card{
            height: 288px;
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            h4{
                color:$cpa-main;
            }
            &:not(:last-of-type){
                margin-bottom: 5%;
            }
            .card-content{
              text-align: right;
              position: absolute;
              right: 0;
              top: 50px;
            }
            .know-more-card{
                .card-back-img{
                    background-color: $cpa-main;
                    img{
                      width: 45%;
                      opacity: 0.4;
                      margin-right: 55%;
                    }
                }
                .card-content{
                    color:$cpa-defualt;
                    p{
                        color:#fff;
                    }
                    h4{
                        color: $cpa-defualt;
                    }
                }

            }
        }
    }
}


  /* /////////////////////////////////////////////////////////

  //////// Media Query

  ////////////////////////////////////////////////////////
   */



  /*
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 768px) and (max-width: 1024px) {

    .services-container{
        height: auto;
        .container{
          height: 100%;
        }
    }



  }

  /*
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .services-container{
        height: 100%;
        .container{
          height: 603px;
        }
    }


  }

  /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
@media (max-width: 768px) {
}
@media (min-width: 768px) and (max-width:992px) {
  .services-container {
    min-height: 1330px;
  }
}
  @media (min-width: 320px) and (max-width: 480px) {
    .services-container{
        height: auto;
        .container{
          height: 100%;
        }
    }
    .custom-card .serciceCard-container .card-content p{
      line-height: 1.5;
    }
    .services-container .custom-row .custom-card .card-content{
      padding: 0 26px 0 0;
    }
    .services-container .custom-row .custom-card .know-more-card .card-content h4{
      font-size: 1.2rem;
    }

  }

@media (max-width:400px){
  .serciceCard-container .card-content p{
    font-size: 0.75rem !important;
  }
}