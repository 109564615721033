@import 'src/styles/variables.scss';
.home-section{
  width: 100%;
  position: relative;
  // background-color: #fff;
  margin:0;
  // padding: 35px 0;
  height: auto;
  margin-bottom: 72px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.home-section-fc{
  width: 100%;
  position: relative;
  background-color: #f8f9fa;
  margin:0;
  padding: 20px 0;
  height: auto;
}
