@import 'src/styles/variables.scss';
.adPopUp-conatiner{
    position: fixed;
    top: 52%;
    left: -282px;
    transition: 1s ease-in-out all;
    color: #fff;
    font-size: 1.5rem;
    width: auto;
    z-index: 10;
    height: 250px;
    text-align: center;
    width:400px;
    #ad-carousel{
        .carousel-indicators{
            display: none;
        }
    }
    .row{
        width: 100%;
    }
    .cpa-ads-content{
        position: relative;
        border-radius: 0 15px 15px 0;
        .col-4{
            margin: 16% 0;
        }
        .cpa-ads-header{
            background-color: $cpa-main;
            border-radius: 0 10px 10px 0;
            padding: 15px 0 15px 0;
            margin: auto 33%;
            width: 88px;
            i[class*="icon"]{
                font-size: 3rem;
            }
        }
        .cpa-ads-content{
            .carousel{
                .carousel-indicators{
                    background-color: red;
                    // -webkit-animation-name: unset;
                    animation-name: unset;
                    -webkit-animation-duration: unset;
                    animation-duration: unset;
                    -webkit-animation-iteration-count: unset;
                    animation-iteration-count: unset;
                    background-color: unset;
                    border: unset;
                    border-radius: unset;
                    position: relative;
                    top: 13rem;
                    width: unset;
                    border-top: unset;
                    border-bottom: unset;
                    height:unset;
                    li.active{
                        &::after{
                            content: unset;
                        }
                    }
                }
            }

            .carousel-item{
                // background-color: #fff;
                background-color: transparent;
                // padding: 20px;
            }
            h3{
                color:#000 ; 
                font-weight: bold;
                font-size: 90%;
            }
            img{
                // width: 80%;
                width: 100%;
                height: 200px;
            }
        }
    }
 
    
    
}
.hover_on{
    // left: -90px;
    left: -15px;
    .cpa-ads-content{
        border-radius: 15px 0 0 15px;
        height: 100%;
        .cpa-ads-header{
            .inner{
                transform: rotate(180deg);
            }
            
        }
    }
}
@media(max-width:768px){
    .adPopUp-conatiner{
        top:24%;
    }
}