@import 'src/styles/variables.scss';
.post-wrapper{
  background: #ffffff url("./bg.png") no-repeat 100% 100%;
  background-size: cover;
  border-radius: 5px;
  font-family: 'Cairo-Regular';

  .shakwa-card{
    .el-input{
      .el-input__inner{
        padding: 24px;
      }
    }
  }

  .card{
    background-color: transparent;
    border:none;
    padding: 50px 0;
    box-shadow: none;
    .card-body{
      text-align: center;
      padding: 0;
      .card-title{
        color: #fff;
        font-size: 1.625rem;
        margin-bottom: 1.5rem;
        text-transform: capitalize
      }
      p{
        color:#fff;
      }
      .card-text{
        font-size: 1.25rem;
        text-align: center;
        line-height: 1.5;
        margin-bottom: 3rem;
        color:#fff;
        text-transform: capitalize
      }
      input{
        height: 50px;
        padding: 15px 70px 15px 15px;
        // background: #ffffff url("https://gdurl.com/TcRJ") no-repeat 97% center;
      }
      .newsletter-icon{
        position: absolute;
        right: 3%;
        bottom: 21%;
        color: $cpa-dark-blue;
      }
      .card-link {
        border: 2px solid #fff;
        padding-bottom: 15px;
        font-weight: normal;
        font-size: 1.1rem;
        height: 50px;
        width: 100%;
        color: #fff;
        &:hover {
          border: 2px solid #000 !important;
          color: #000 !important;
          background-color: transparent !important;

        }
        &:focus{
          outline: none;
        }
      }
      .submit-btn{
        position: relative;

        button{
          height: 50px;
          background-color: transparent;
          border: 2px solid #fff;
          text-align: right;
          font-weight: normal;
          font-size: 1.1rem;
          width: 100%;
          &:hover {
            border: 2px solid #000 !important;
            color: #000 !important;

          }
          &:focus{
            outline: none;
          }
          &:after{
            position: absolute;
            top: 16px;
            right: 80%;
          }
        }
        .dropdown-menu.show{
          text-align: right;
          padding: 1rem;
          z-index: 99;
        }
      }
    }
  }
}

//////////////////////////////////////////////////////

/////////// Media Query

/////////////////////////////////////////////////////


/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {



}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .post-wrapper .card .card-body .submit-btn{
    margin-top: 25px;
  }
  .post-wrapper .card .card-body .submit-btn button,
  .post-wrapper .card .card-body .card-link{
    height: 50px;
    font-size: 1rem;
    margin-top: 20px;
  }
  .newsletter-icon{
    left: 91%;
    bottom: 33%;
  }


}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  .post-wrapper .card .card-body .submit-btn{
    margin-top: 0px;
  }
  .post-wrapper .card .card-body .submit-btn button,
  .post-wrapper .card .card-body .card-link{
    height: 50px;
    font-size: 1rem;
    margin-top: 0;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 768px) {
  .post-wrapper .card .card-body .submit-btn{
    margin-top: 10px;
  }
  .post-wrapper .card .card-body .newsletter-icon{
    position: absolute;
    left: 94%
  }

}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .post-wrapper .card .card-body .submit-btn{
    margin-top: 25px;
  }
  .post-wrapper .card .card-body .submit-btn button,
  .post-wrapper .card .card-body .card-link{
    height: 40px;
    font-size: 0.8rem;
  }
  .post-wrapper .card .card-body .newsletter-icon{
    position: absolute;
    left: 90%
  }
}
.subscribe-modal .modal-content{
  width: 100% !important;
}