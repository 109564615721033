@import 'src/styles/variables.scss';
.img-card{
    position: relative;
    height: fit-content;
    width: 100%;
    .back-color{
        position: absolute;
        background: $cpa-light-gray;
        width: 100%;
        height: 370px;
        z-index: 0;
    }

    img{
        max-width: 100%;
        position: relative;
        z-index: 1;
        left: 20px;
    }
    .img_test{
        width: 50%;
        margin-bottom: 25px;
        margin: auto;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            background-color: #F5F5F7;
            top: -30px;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }
    }
}



@media (max-width: 425px) {
    .img-card img{
        width: 85%;
    }
  }

