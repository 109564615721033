@import "src/styles/variables.scss";

.userProfile_container {
    .userProfile-inner {
        .user-img-container {
            .user-img {
                height: 225px;
                width: 225px;
                background-size: cover;
                background-position: center;
                border-radius: 50%;
                margin: auto;
                position: relative;
                margin-top: 62px;
                margin-bottom: 33.5px;
                border: 4px solid #fff;
                // background-image: url('https://cpa.org.sa:443/Images/Users_new/data binding.png');
                .changeImg {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    .input-btn-layout {
                        background: $cpa-main;
                        color: #fff;
                        width: 48px;
                        height: 48px;
                        font-size: 1.875rem;
                        font-weight: bold;
                        border-radius: 50%;
                        padding: 7px 15px;
                    }
                    input {
                        position: absolute;
                        right: 0;
                        top: 6px;
                        width: 48px;
                        opacity: 0;
                    }
                }
            }
        }

        .profile-content {
            width: 100%;
            background: #fff;
            padding: 25px;
            border-radius: 10px;
            .nav-pills {
                border-bottom: 3px solid $cpa-main;
            }
            .nav-item {
                padding: 0;
            }
            .nav-pills .nav-link {
                background: transparent;
                color: $cpa-defualt;
                border-radius: 0;
                text-align: center;
                font-size: 1.25rem;
                padding: 21px;
            }
            .nav-pills .nav-link.active {
                border-radius: 15px 15px 0 0;
                background: $cpa-main;
                color: #fff;
            }
        }
    }
}
.tabUserForm-container {
    padding: 35px;
    .image-preview-container {
        margin-top: 25px;
        height: 225px;
        img {
            height: 100%;
        }
    }
    .form-btn {
        text-align: left;
    }
    .input-file {
        position: relative;
        input[type="file"] {
            position: absolute;
            top: 0;
            width: 100%;
            opacity: 0;
            z-index: 2;
        }
        i {
            position: absolute;
            left: 15px;
            top: 5px;
            z-index: 1;
        }
        p {
            position: absolute;
            top: 5px;
            right: 0.75rem;
            z-index: 1;
        }
    }
    select:disabled {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}
.tabServices-container {
    h4 {
        color: $cpa-main;
        font-size: 1.5rem;
        margin-top: 15px;
    }
    .table-container {
        padding: 15px;
    }
}

.userName {
    text-align: center;
    color: #42c194;
}

.tableTitle {
    color: #42c194;
}

.customPaginationItem {
    transform: rotate(180deg);
}
