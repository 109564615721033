@import 'src/styles/variables.scss';
.worldDays-container{
    text-align: center;
    .worldDays-container{
        margin-top: 25px;
    }
    .container-card{
        .container-available-job-card{
            .card-content{
                margin-bottom: 1rem;
                height: auto;
            }
        }
    }
    
    .img-body{
        width:100%;
        img{
            width: 100%;
        }
    }
    .inner_for_more{
        position: absolute;
        bottom: 0;
        width: 100%;
        // height: 100%;
        font-size: 3rem;
        color:#fff;
        // padding: 37%;
        // transform: rotate(180deg);
    }
}