.fqa-container{
    .collapse-head{
        padding: 15px 15px 10px 70px;
        P{
            font-size: 1.125rem;
        }
    }
    .collapse-content{
        P{
            font-size: 1rem;
        }
    }
}