@import 'src/styles/variables.scss';

.flex-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &:first-of-type{
        margin-top: 50px;
    }
    .flex-card{
        width: 100%;
        padding: 10% 5%;
        margin-bottom: 25px;
        border-radius: 5px;
        overflow: hidden;
        background-color: #fff;
        box-shadow: 0 1px 4px #999;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        ul{
            list-style: none;
            text-align: center;
            padding: 20px 0;
            li{
                i[class*="icon"]{
                    font-size: 2rem;
                    color:$cpa-main;
                    margin-bottom: 20px;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                }
                h3{
                    color:$cpa-dark-blue;
                    margin: 35px 0;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                }

            }
        }
    }

}
