@import 'src/styles/variables.scss';
.toggle-button{
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  color: #fff;
  font-size: 1.5rem;
  height: 100%;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:focus{
    outline: none;
  }
}
.header-shrink{
  .toggle-button{
    color:$cpa-main;
  }
}


/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .toggle-button{
    padding: 0 20px;
  }

}
