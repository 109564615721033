.over-lay {
    background: #00000025;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999999;
    color: #fff;
    text-align: center;
    font-size: 3.25rem;
    padding: 20% 0;
}
.search-container {
    align-items: center;
    // width: 70%;
    display: flex;
    flex-direction: column;
    h4 {
        // margin-top: 2rem;
    }
    a {
        // width: 60%;
        width: 90%;

        .cpa-tooltip {
            // width: 30rem;
            width: 100%;

            h4 {
                white-space: normal;
            }
        }
        .consumer-container {
            display: flex;
            flex-direction: row;
            width: 100%;

            overflow: hidden;
            margin-bottom: 1rem;
            margin-top: 1rem;
            align-items: center;
            .card-img {
                // width: 25%;
                flex: 1;
                margin-left: 5%;
                img {
                    max-width: 15rem;
                    height: auto;
                    object-fit: fill;
                }
            }
            .card-content {
                flex: 4;
                display: flex;
                flex-direction: column;
                // align-items: center;
                margin: 1rem;
                // padding: 52px 43px;
                .card-viewData {
                    i {
                        color: #215c80;
                        font-size: 1.2rem;
                        margin-left: 5px;
                        padding-right: 7px;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .search-container {
        a {
            .cpa-tooltip {
                h4 {
                    text-align: center;
                }
            }
            .card-viewData {
                justify-content: center;
            }
            .consumer-container {
                flex-direction: column;

                .card-img {
                    margin-left: 0px;

                    img {
                        display: block;
                        margin: auto;
                        width: 100%;
                        height: 200px;
                        object-fit: fill;
                    }
                }
            }
        }
    }
}
