@import 'src/styles/variables.scss';
.pagination{
    .page-item{
        .page-link{
            border-color: $cpa-defualt;
            border-width: 2px;
            color:$cpa-defualt;
            border-radius: 5px;
            margin: 0 6px;
            padding-top: 4px;
            padding-bottom: 5px;
            transform: rotate(180deg);
        }
    }
    .active{
        transform: rotate(180deg);
        .page-link{
            border-color:$cpa-main !important; 
            color:$cpa-main !important;
            background-color: #fff !important;
        }
    }
}