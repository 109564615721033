@import 'src/styles/variables.scss';

.contacts-container {
    background-color: white;
    .complain-header{
        margin-top: 3rem;
        color: #265C7D;
    }
    .btn.submit-cpa-btn{
        margin-top: 1rem;
    }

    .contacts-content {
        border-bottom: 1px solid rgba($color: #707070, $alpha: 0.2);
        padding: 15px 0;
        .info-container {
            margin-right: 30px;
        }

        .address-content {
            .icon-company{
                color: #265C7D;
            }

        }
        .info-container{
            border-left: 1px solid rgba($color: #707070, $alpha: 0.2);
            strong{
                color: #3EBF91;
            }
        }

        .form-container {
            border-top: 1px solid rgba($color: #707070, $alpha: 0.2);
            padding-top: 50px;
            margin-top: 30px;

            form {
                textarea {
                    height: 170px;
                }

                .message-icon {
                    color: #3EBF91;
                }

                button {
                    height: 50px;
                    width: 150px;
                    background-color: #2F6688;
                    color: white;
                    outline: none;
                    border: none;
                    margin-bottom: 50px;
                    margin-top: 20px;

                    i {
                        color: white !important;
                        vertical-align: middle;
                        // margin-right: 10px;
                    }
                }
            }
        }

        h3 {
            text-align: center;
            color: $cpa-main;
            margin: 35px;
        }

        h4 {
            text-align: center;
            color: $cpa-dark-blue;
            margin-bottom: 50px;
            font-size: 25px;
        }

        h5 {
            text-align: initial;
            color: $cpa-main;
            margin-bottom: 30px;
        }

        i[class*="icon"] {
            font-size: 25px;
            color: $cpa-dark-blue;
            // margin-left: 10px;
        }

        p {
            text-align: initial;
            color: $cpa-black;
            font-size: 1rem;

            strong {
                font-weight: bold;
                margin-left: 5px;
            }

        

            a:hover {
                text-decoration: underline !important;
                color: $cpa-gray !important;
                cursor: pointer !important;
            }
        }
    }

}
.address-container{
    strong{                
        color: #3EBF91;
    }
    p{
        color: $cpa-black;
    }
    display: flex;
}
.contact-info-conatiner{
    text-align: right;
}

@media  (min-width: 320px)  {
    .contacts-content{
        height: 180vh;
    }
}
@media  (min-width: 375px)  {
    .contacts-content{
        height: 170vh;
    }
}
@media  (min-width: 425px)  {
    .contacts-content{
        height: 170vh;
    }
}

@media (min-width: 768px) {
    .contacts-content{
        height: 150vh;
    }
  }

  @media  (min-width: 1024px)  {
        .contacts-content{
            height: unset;
        }
    }

  