@import "src/styles/variables.scss";
.newsCard-container {
    .new-news {
        .card-img {
            text-align: center;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .more-news {
        .card-img {
            text-align: center;
            img {
                width: unset !important;
                height: 100%;
            }
        }
    }

    .cpa-tooltip .cpa-tooltiptext {
        top: 35px;
    }
    .card-content {
        line-height: 30px;
        color: $cpa-defualt;
        h4 {
            font-size: 1.125rem;
            color: $cpa-main;
        }
        p {
            font-size: 0.75rem;
        }
        i[class*="icon"] {
            color: $cpa-dark-blue;
            margin-left: 5px;
            font-size: 1.2rem;
        }
    }
    .img_card {
        div {
            display: none;
        }
    }
    .video_card {
        img {
            display: none;
        }
    }
}
