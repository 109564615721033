.shareRating-container{
.topMenu{
    text-align: center; 
     margin-top: 3.2rem; 
     margin-bottom: 25px;
     button{
         margin:5px;
         &.active{
             background-color: white;
             color: #42C194;
        }
        }
}
    iframe{
        margin-top: 3rem;
        width: 100%;
        height: 50rem;
    }
}

