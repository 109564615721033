@import "src/styles/variables.scss";
.singleCard-container {
    line-height: 30px;
    .main-card {
        margin-bottom: 80px;
    }
    .related-news {
        margin-bottom: 150px;

        .title {
            margin-bottom: 36px;
            color: $cpa-dark-blue;
            font-size: 1.5rem;
        }
        .newsCard-container {
            margin-bottom: 24px;
            .card-content {
                padding: 25px 15px;
            }
        }
    }
    .slick-arrow.slick-prev::before {
        color: #42c194;
    }
    .slick-arrow.slick-next::before {
        color: #42c194;
    }
}
@media (max-width: 425px) {
    .singleCard-container .main-card .main-img {
        margin-bottom: 0;
    }
    .singleCard-container .main-card .main-img .main-links ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .singleCard-container .main-card .main-img .main-links ul li {
        margin-bottom: 0;
    }
}
