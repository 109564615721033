@import "src/styles/variables.scss";

@media only screen and (min-width: 320px) {
    // .modal-content {
    //     left: 4%;
    //     width: 18rem !important;
    // }
}

@media only screen and (min-width: 375px) {
    // .modal-content {
    //     left: 1%;
    //     width: 22rem !important;
    // }
}
@media only screen and (min-width: 425px) {
    // .modal-content {
    //     left: 2%;
    //     width: 25rem !important;
    // }
}
@media only screen and (min-width: 768px) {
    // .modal-content {
    //     width: 40rem !important;
    //     left: -9%;
    // }
}
@media only screen and (min-width: 1024px) {
    .modal-content {
        width: unset !important;
        left: 1%;
    }
}

.tests-container {
    .tests-content {
        margin-top: 25px;
        .guide-card {
            text-align: center;
            background-color: #fff;
            padding: 35px;
            border-radius: 10px;
            cursor: pointer;
            &:hover {
                box-shadow: 0 8px 9px #999;
                h4 {
                    font-weight: bold;
                }
            }
            .inner {
                padding: 25px;
                border: 2px solid #42c194;
                border-radius: 10px;
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    i[class*="icon"] {
                        color: $cpa-main;
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}

.test-section {
    // max-width: 70% !important;
    .modal-body {
        padding: 63px;
        .test-header {
            width: 100%;
            margin: 1rem 0;
            text-align: center;
            h4 {
                font-size: 1.75rem;
                color: $cpa-dark-blue;
                font-weight: bold;
                margin: 0 0 20px;
            }
            p {
                font-size: 1rem;
                color: $cpa-black;
            }
        }
        .test-content {
            width: 100%;
            padding: 35px;
            border: 1px solid #e4edf0;
            margin: 30px 0;
            color: $cpa-black;
            text-align: right;
            p {
                font-size: 1rem;
                // font-weight: bold;
                margin-bottom: 20px;
                -webkit-transition: all 0.1s ease-in-out;
                -moz-transition: all 0.1s ease-in-out;
                -o-transition: all 0.1s ease-in-out;
                transition: all 0.1s ease-in-out;
            }
            .test-answers {
                .progress-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .progress-section {
                        width: 95px;
                        height: 95px;
                        display: block;
                        position: relative;
                        margin: 0 auto;
                        span {
                            position: absolute;
                            top: 35%;
                            text-align: center;
                            left: 0;
                            right: 0;
                            font-size: 1.5rem;
                            color: $cpa-black;
                        }
                    }
                }

                .form-check {
                    label {
                        display: block;
                        position: relative;
                        font-weight: 300;
                        font-size: 1em;
                        padding: 0 25px 0 0;
                        margin: 5px auto;
                        z-index: 9;
                        cursor: pointer;
                        -webkit-transition: all 0.1s ease-in-out;
                        -moz-transition: all 0.1s ease-in-out;
                        -o-transition: all 0.1s ease-in-out;
                        transition: all 0.1s ease-in-out;

                        input[type="radio"] {
                            position: absolute;
                            visibility: hidden;
                        }
                    }
                    .check {
                        display: block;
                        position: absolute;
                        border: 2px solid #8d8e8e;
                        border-radius: 50%;
                        height: 17px;
                        width: 17px;
                        top: 8px;
                        right: 0;
                        // -webkit-transition: all 0.4s ease-in-out;
                        // -moz-transition: all 0.4s ease-in-out;
                        // -o-transition: all 0.4s ease-in-out;
                        // transition: all 0.4s ease-in-out;
                        &:after {
                            display: block;
                            position: absolute;
                            content: "";
                            border: 2px solid #fff;
                            border-radius: 50%;
                            height: 13px;
                            width: 13px;
                            top: 0;
                            right: 0;
                            // -webkit-transition: all 0.4s ease-in-out;
                            // -moz-transition: all 0.4s ease-in-out;
                            // -o-transition: all 0.4s ease-in-out;
                            // transition: all 0.4s ease-in-out;
                        }
                    }
                }
            }
        }
        .form-check {
            position: relative;
            .ans-icon {
                position: absolute;
                top: 5px;
                left: 5px;
            }
        }
        .ans-icon {
            display: none;
            .icon-ok,
            .icon-cancel {
                display: none;
            }
        }
        .rightAns {
            color: green;
            .ans-icon {
                display: block;
                .icon-ok {
                    display: block;
                }
            }
        }
        .wrongAns {
            color: red;
            .ans-icon {
                display: block;
                .icon-cancel {
                    display: block;
                }
            }
        }
        .test-button {
            text-align: center;
            button {
                background-color: $cpa-main !important;
                cursor: pointer;
                -webkit-transition: all 0.1s ease-in-out;
                -moz-transition: all 0.1s ease-in-out;
                -o-transition: all 0.1s ease-in-out;
                transition: all 0.1s ease-in-out;
                &:focus,
                &:active {
                    outline: none;
                }
            }
        }
        @media (max-width: 768px) {
            padding: 15px;
        }
    }
}

input[type="radio"]:checked ~ .check {
    border: 2px solid $cpa-main !important;
}

input[type="radio"]:checked ~ .check::after {
    background-color: $cpa-main;
}
@media (max-width: 768px) {
    .test-section .modal-body .test-content {
        .test-answers {
            div[class*="col"] {
                margin-bottom: 25px;
            }
        }
    }
}

@media (max-width: 426px) {
    // .modal-content{
    //   width: 135% !important;
    // }
    .modal-header {
        .close {
            align-items: unset !important;
        }
    }
}

@media (max-width: 325px) {
    // .modal-content{
    //   width: 130% !important;
    // }
    .modal-header {
        .close {
            align-items: unset !important;
        }
    }
    // .test-section .modal-body .test-content{
    //   padding: 10px !important;
    // }
}

.cpa-body[dir="ltr"] {
    + .tests-container {
        .test-section {
            .modal-body {
                .test-content {
                    text-align: left !important;
                }
            }
        }
    }
}
