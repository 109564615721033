.footer-wrapper {
  background-color: #fff !important;
  /* height: 45px; */
  position: relative;
  width: 100%;
  box-shadow: 0 1px 7px #ccc;
  text-align: right;
}
.footer_bottom {
  border-top: 1px solid #f5f5f5;
  padding-top: 15px;
}
.footer-wrapper .navbar-nav{
  justify-content: space-between;
  width: 100%;
}
.footer-wrapper {
  /* position: absolute; */
  /* bottom: 0; */
  /* height: 100%; */
  line-height: 30px;
  padding: 15px 0;
}
.footer-wrapper .about-association{
  text-align: center
}
.footer-wrapper .cpa-divider{
  margin: 0;
  width: 100%;
}
.footer-wrapper .about-association img{
  width: 85px;
  height: 100px;
  margin-bottom: 15px;
}
.footer-wrapper .about-association p{
  width: 80%;
  line-height: 2;
  margin: auto;
}
.footer-wrapper .navbar{
  padding: 0;
  padding-top: 15px;
}
.footer-side{
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  justify-content: center;
  /* height: 77px; */
}
.footer-side span.navbar-text{
  color: #000;
  display: flex;
  line-height: 1;
}
.footer-side a{
  color: #3FC193;
  text-decoration: none;
}
.footer-wrapper a i[class*="icon"]{
  margin: 0 10px;
  font-size: 1rem;
}
.footer-wrapper .nav-link{
  padding: 0;
  font-size: 1rem;
}
.footer-wrapper .nav-link a{
  color: #1C2128;
}
.copyright{
  font-size: 0.75rem;
}
.copyright a{
  color:#215C80;
  font-weight: bold;
  margin: 0 15px;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  .footer-wrapper{
    height: auto;
  }
  .footer-wrapper .navbar-content-main{
    line-height: 1;
    

  }
  a i{
    text-transform: capitalize;
  }
  .footer-wrapper .footer-side{
    align-items: center;
    height: auto;
  }
  .footer-wrapper .navbar-nav{
    flex-direction: row;
    padding: 0 0 24px;
  }

  .footer-wrapper .nav-link{
    font-size: 1rem;
    
  }


}
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  .footer-wrapper{
    height: auto;
  }
  .footer-wrapper .navbar-content-main{
    line-height: 77px;

  }
  .footer-wrapper .footer-side{
    align-items: center;
    height: auto;
  }
  .footer-wrapper .navbar-nav{
    flex-direction: row;
    padding: 0 0 24px;
  }
  .footer-side span.navbar-text{
    margin-bottom: 10px;
  }
  .footer-wrapper .nav-link{
    font-size: 1rem;
    text-align: center;
  }

}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 320px) and (max-width: 767px) {
  .footer-wrapper{
    height: auto;
  }
  .footer-wrapper .navbar-content-main{
    line-height: 1;

  }
  .footer-wrapper .footer-side{
    align-items: center;
    height: auto;
    margin: auto;
  }
  .footer-wrapper .navbar-nav{
    flex-direction: row;
    padding: 0 0 24px;

  }
  .footer-side span.navbar-text{
    margin-bottom: 10px;
  }
  .footer-wrapper .nav-link{
    font-size: 12px;
    text-align: center;
  }

}
