@import 'src/styles/variables.scss';
.mediaInterview-container{
    .media-icon{
        position: absolute;
        top:7%;
        left: 7%;
        z-index: 20;
        
        i[class*="icon"]{
            color:#fff;
            font-size:1.5rem;
        }
        h3{
            margin: 0;
        }
        .cpa-tooltip .cpa-tooltiptext{
            width: max-content;
        }
    }
    .container{
        // margin-top: 35px;
        button{
            margin-bottom: 25px;
            border-radius: 5px;
        }
        h5{
            font-size: 1.5rem;
            color:$cpa-dark-blue;
            margin: 35px 0;
            i[class*="icon"]{
                margin-left: 5px;
                color:$cpa-main;
            }
        }
        .media-card{
            position: relative;
            // &:hover{
            //     h4{
            //         font-weight: bold;
            //         text-shadow: 9px 10px 2px #999;
            //     }
            // }
        }
    }
    .more-container{
        // .text-center{
        //     .pagination{
        //         flex-direction: row-reverse;
        //     }
        // }
        // margin-top: 35px;
        .cpa-divider{
            margin: 18px auto;
        }
        .media-card:last-of-type{
            .cpa-divider{
                display: none;
            }
        }
        
        
        .media-card{
            // height: 300px;
            padding: 20px 0;
            .row{
                height: 100%;
                .col-12{
                    height: 100%;
                }
            }
            .media-img{
                // position: relative;
                // height: 100%;
                img{
                    height: 100%;
                    width: 100%;
                    border-radius: 0 10px 10px 0;
                }
            }
            .media-content{
                line-height: 30px;
                color:$cpa-defualt;
                text-align: right;
                // position: relative;
                // top:40%;
                h4{
                    font-size: 1.25rem;
                    color: $cpa-main;
                }
                p{
                    font-size:1rem;
                }
                i[class*="icon"]{
                    color:$cpa-dark-blue;
                    margin-left: 5px;
                    font-size: 1.2rem;
                }
            }
        }
    }
    
}
