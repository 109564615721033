@import 'src/styles/variables.scss';
.icon-card{
    font-family: 'Cairo-Regular';;
    .card-icon{
        color:$cpa-main;
    }
    .card-desc{
        line-height: 30px;
        // height: 180px;
        height: 10rem;
        overflow: hidden;    
        text-overflow: ellipsis;
        // white-space: nowrap;
    }
    .show_controller{
        text-align: left;
        color:$cpa-gray;
        cursor: pointer;
    }
}