@import "src/styles/variables.scss";

.container-card {
    min-height: 230px;
    // box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-radius: 2px;
    padding: 0%;

    .inner {
        border: 0px;
        padding: 10px;
    }
}

.container-available-job-card {
    font-family: "Cairo-Regular";

    .form-button {
        bottom: 13px;
        right: 0;
        left: 0;
        position: absolute;
        text-align: center;
    }

    button {
        padding: 0px 20px;
        border-radius: 9px;
        background-color: $cpa-main;
        color: white;
        font-family: "Cairo-Regular";
        font-weight: 400;
    }

    .card-content {
        height: 400px !important;
        text-align: center;
        padding-top: 0px;

        h4 {
            color: $cpa-main;
            font-size: 1.125rem;
            margin-bottom: 14px;
        }

        p {
            color: $cpa-gray;
            font-size: 0.875rem;
            margin-bottom: 0px;
        }

        .card-img {
            width: 90%;
            height: 200px;
            max-height: 50%;
            padding-top: 1rem;
        }

        .card-links {
            text-align: right;

            a {
                font-size: 1.75rem;
            }

            span {
                font-size: 1.125rem;
                position: relative;
                top: -5px;
            }
        }
    }

    .card-desc {
        p {
            margin-top: 33px;
            text-align: right;
            color: $cpa-defualt;
            font-size: 1.125rem;
            padding: 0;
            line-height: 42px;
        }
    }
}

.hide-desc {
    display: none !important;
}
