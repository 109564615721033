@import 'src/styles/variables.scss';
.LegalAdvocacy-container{

    .nav-item{
        height: 100%;
        a{
            padding: 0;
            opacity: 0.6;
            &:hover{
                opacity: 0.8;
            }
        }
        h5{
            font-size: 1.125rem !important;
            margin-top: 10px;
        }
        .landing-card{
            min-height: 132px;
            max-height: 160px;
            padding: 15% 10% !important;
            margin-bottom:0 !important;

        }
        .active{
            background-color: unset !important;
            opacity: 1;
            .landing-card{
                background-color: $cpa-main;
                position: relative;
                i[class*="icon"],h5{
                    color:#fff;
                }
                :after{
                    content: "";
                    position: absolute;
                    bottom: -12px;
                    right: 44.5%;
                    width: 0; 
                    height: 0; 
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-top: 12px solid $cpa-main;
                }

            }
        }
    }
    .choose-case{
        padding: 20px 20px 0 20px;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        background-color: #fff;
        padding: 35px 20px;
        height: auto;
        transition: 0.75s all ease-in-out;
        overflow: hidden;
        h5{
            font-size: 1.25rem;
            color:$cpa-main;
            margin-bottom: 25px;
        }
        .select-case{
            margin-bottom: 35px;
        }
        .shoose-btn{
            position: relative;
            top:-10px;
        }
    }
    .cpa-tooltip{
        padding-top: 5px;
        .cpa-tooltiptext{
            left: 1px;
        }
    }
    .form-check{
        text-align: right;
    }
    .submit-row{
        text-align: left;
        &-error{
            color: #c14242;
            text-align: left;
        }
    }
    // .guideUs-intro{
    //     padding: 0 ;
    // }
    .success_guideUs{
        h2{
            color:$cpa-dark-blue
        }
        h3{
            color:$cpa-main
        }
    }
}

.attachedFilesPreview{
    display: flex
}

.attachedFile{
    display: flex;
    background: #42c194;
    padding: 5px;
    border: 1px solid #42c194;
    border-radius: 10px;
    margin: 5px 7.5px;
}

.deleteFileBtn{
    margin-left: 10px !important;
    color: white !important;
    font-size: 1.5rem !important;
    position: relative !important;
    top: -1px !important;
}

.btnFileName{
    color: white !important;
    font-size: 1rem !important;
}
.guideUs-modal{
    h3{
        color:$cpa-dark-blue;
    }
    h2{
        margin-top: 25px;
        font-size: 1.5rem;
        color:$cpa-main
    }
}
@media(max-width:425px){
    .LegalAdvocacy-container{
        .submit-row.btn{
            text-align: right;
            top: 2.5rem;
        }
    }
}