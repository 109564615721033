@import 'src/styles/variables.scss';
.container-card{
    padding: 10px;
    width: fit-content;
    background: #fff;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    width: 100%;
    .inner{
        border: 2px solid $cpa-light-gray;
        padding: 20px;
    }
}
