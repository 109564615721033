.cpa-body[dir="ltr"] {
    .profile-card-container {
        &-details {
            text-align: left;
        }
    }

    .search-container {
        h4 {
            text-align: left;
        }
    }

    .CookieClass {
        p {
            text-align: left;
            padding-left: 1rem;
        }
        .btn {
            float: right;
            margin-right: 1rem;
        }
    }

    .articaleCard-container {
        .main-header {
            h5 {
                text-align: left;
            }
            .date {
                text-align: left;
            }
        }
        .main-conten {
            text-align: left;
        }
    }

    .alljobs-container {
        .title {
            text-align: left;
        }
    }

    .data-conatiner {
        .title {
            text-align: left;
        }
        .sub-title {
            text-align: left;
        }
        .newsCard-container {
            .card-content {
                h4 {
                    text-align: left;
                }
            }
        }
    }

    .news-conatiner {
        .title {
            text-align: left;
        }
        .sub-title {
            text-align: left;
        }
    }

    .takeScience-container {
        h3 {
            text-align: left;
        }
    }

    .motmaken-container {
        .motamaken-landing {
            p {
                text-align: left;
            }
        }
    }

    .mt-5 {
        text-align: left;
    }

    #indicativeContracts-container {
        .tab-pane {
            h4 {
                text-align: left;
            }
            p {
                text-align: left;
            }
        }
        .flex-row:first-of-type {
            .nav-item {
                a {
                    text-align: left;
                }
            }
        }
    }

    .cpa-tooltip .cpa-tooltiptext {
        right: 0;
    }

    .about-row {
        .icon-card {
            .card-head {
                h3 {
                    text-align: left;
                }
            }
        }
    }

    .userProfile_container {
        .image-preview-container {
            text-align: left;
        }
    }

    .consumer-consultant-container {
        .episode-container {
            .newsCard-container {
                text-align: left;
            }
        }
    }

    .consumer-consultant-container:hover {
        .consumer-action-hover {
            text-align: left;
        }
    }

    .ComprehensiveGuide-container {
        .dropdown {
            .btn {
                text-align: left;
            }
            .dropdown-toggle::after {
                margin-left: 7% !important;
            }
        }
        .sharing-downloading--container {
            right: 0;
            left: 15rem;
        }
    }
    @media (max-width: 425px) {
        .ComprehensiveGuide-container {
            .sharing-downloading--container {
                right: 0;
                left: unset;
            }
        }
    }

    .guideBook-container {
        // .dropdown{
        //     .btn{
        //         text-align: left;
        //     }
        // }

        .guideBook-content {
            h1 {
                text-align: left;
            }

            label {
                text-align: left;
            }
            .contentIcon {
                border-radius: 20px 0px 0px 20px;
            }

            .contentMap {
                margin-left: 0;
                border-radius: 0px 20px 20px 0px;
                p {
                    text-align: left;
                }
            }
            .imgBiggerContainer {
                padding-left: 1.8rem;
            }
        }
    }

    .MotamakenSingle-container {
        h4 {
            text-align: left;
        }
    }

    .customHeader-container {
        .search-form {
            left: 0;
            &:hover {
                .search-input {
                    // left: 4rem;
                    padding: 0 50px;
                }
            }
        }
    }

    .card-footer > :first-child {
        float: right !important;
    }

    .card-footer > :nth-child(2) {
        float: left !important;
    }
    .navbar-nav {
        text-align: left;
    }
    .condtions-list {
        text-align: left;
        margin-left: 15%;
        list-style-type: disc;
    }

    .container-members {
        .content {
            text-align: left;
        }
    }

    .container-available-job-card {
        .form-button {
            width: 100%;
            left: 0;
            bottom: 10px;
            position: relative;
            margin-top: 15%;
        }

        button {
            padding: 0px 20px;
            border-radius: 9px;
            background-color: $cpa-main;
            color: white;
            font-family: "Cairo-Regular";
            font-weight: 400;
        }
    }

    .container-available-job-card .card-content .condtions-list li {
        text-align: left;
    }

    .moreBtnCard {
        text-align: right;
    }

    .dropdown-menu {
        text-align: left;
        left: -10px;
    }

    .side-drawer {
        right: 0;
        left: unset;
        transform: translateX(100%);

        .side-drawer-links {
            text-align: left;
            direction: ltr;
            h5::after {
                right: 0;
                left: unset;
            }

            .menu-icon::before {
                left: 4px;
                right: unset;
            }
        }

        &.open {
            transform: translateX(0);
        }
    }

    .services-container {
        .card-icon {
            right: unset;
            left: 19px;
        }

        .card-content {
            text-align: left;
        }

        .custom-row .custom-card {
            .know-more-card .card-back-img img {
                transform: rotateY(180deg);
                margin-left: 55%;
            }

            .card-content {
                right: unset;
                left: 15px;
            }
        }
    }

    .details-slider-caption .carousel-caption {
        text-align: left;
    }

    .post-wrapper {
        .shakwa-card {
            .el-input {
                .el-input__inner {
                    padding: 24px;
                }
            }
        }
    }

    .post-wrapper .card .card-body {
        .newsletter-icon {
            right: unset;
            left: 3%;
        }

        input {
            padding: 15px 15px 15px 70px;
        }
    }

    @media (max-width: 1024px) and (min-width: 769px) {
        .newsletter-icon {
            right: 91%;
            left: unset;
            bottom: 30px !important;
        }
    }

    .footer-wrapper {
        text-align: left;
    }

    .icon-card {
        padding-left: 5%;

        .img-card {
            margin-left: 0;
            margin-right: 7%;
        }
    }

    .container-id-card .card-content {
        text-align: left;

        .card-links {
            text-align: left;
        }
    }

    .icon-angle-double-left:before {
        transform: rotate(180deg);
    }

    .board-container {
        text-align: left;
    }

    .newsCard-container .card-content .counter-wrapper .row button {
        left: unset;
        right: 1.25rem;
        padding: 8px 25px 14px 25px;
    }

    .required:after {
        left: unset;
        right: -15px;
    }

    .complain-container .form-group .form-input span {
        text-align: left;
    }

    .form-input input,
    .form-input select,
    .form-input textarea {
        text-align: left;
        padding-right: 0;
        padding-left: 40px;
    }

    .form-input i[class*="icon"] {
        right: unset;
        left: 8px;
    }

    .file-input .custom-input-file {
        padding-right: 0;
        padding-left: 40px;
        text-align: left;
    }

    .form-check {
        text-align: left;
    }

    .share-control {
        text-align: left;
    }

    .annualReports-container
        .reports-content
        .report-card
        .report-title
        .report-controllers
        button {
        &:not(:last-of-type) {
            margin-left: unset;
            margin-right: 10px;
        }

        i[class*="icon"] {
            margin-right: 10px;
            margin-left: unset;
        }
    }

    .mediaInterview-container .more-container .media-card .media-img img {
        border-radius: 10px 0 0 10px;
    }

    .mediaInterview-container {
        .container {
            h5 {
                text-align: left;
            }
        }
        .more-container {
            .pagination {
                flex-direction: row;
            }
        }
        .media-content {
            text-align: left;
        }
    }

    .LegalAdvocacy-container {
        .data-complain {
            text-align: left;
        }
        .choose-case {
            h5 {
                text-align: left;
            }
        }
        .form-check {
            text-align: left;
            left: 37rem;
        }

        .submit-row {
            text-align: right;
            &-error {
                text-align: right;
            }
        }
        .cpa-tooltip {
            text-align: left;
        }
    }

    .videoCard-container .card-content i[class*="icon"] {
        margin-left: 0;
        margin-right: 10px;
    }

    .videoCard-container {
        .card-content {
            h4 {
                text-align: left;
            }
        }
    }

    .service_ProductPage-container
        .service_ProductPage-content
        .compareand_rate_controllers {
        text-align: right;
    }

    .landing-container .landing-row .landing-card ul li .icon-Observation {
        right: unset;
        left: -10px;
    }

    .landing-card--body {
        text-align: left !important;
    }

    // .contact-info-conatiner {
    //     text-align: left;
    // }

    .contacts-container .contacts-content .address-content {
        border-left: unset;
        // border-right: 1px solid rgba(112, 112, 112, 0.2);
    }

    .contacts-content {
        .info-container {
            border-left: unset;
            border-right: 1px solid rgba(112, 112, 112, 0.2);
        }
    }

    .toggle-button {
        transform: rotateY(0deg);
    }

    .header-icons .icon-wrapper:first-child {
        margin-left: 0;
        margin-right: 15px;
    }

    //   .worldDays-container .inner_for_more{
    //       transform: rotate(0deg);
    //   }
    .navbar-content-main .beta_span {
        left: 45px;
        right: unset;
    }

    // .isScrolling .navbar-content-main .beta_span{
    //     left: -55px;
    //     right: unset;
    // }
    @media (max-width: 991px) {
        .navbar-content-main .beta_span {
            left: 15px;
            right: unset;
        }
    }

    @media (max-width: 738px) {
        .navbar-content-main .beta_span {
            height: 30px;
        }
    }

    .collapse-head::after {
        left: unset;
        right: 35px;
    }

    @media (max-width: 425px) {
        .fqa-container {
            .collapse-head {
                padding: 15px 15px 10px 0px !important;
            }
            .collapse-head::after {
                left: unset;
                right: 35px;
                top: unset;
                bottom: 1.5rem;
            }
        }
    }

    .complain-container .signin-button {
        left: unset;
        right: 45px;
    }

    .cpa-select.open {
        .select-options {
            text-align: left;
        }
    }

    .cpa-select .input-select input {
        padding-right: 0px;
        padding-left: 40px;
    }

    .cpa-select .input-select .icon-menu-media {
        right: unset;
        left: 8px;
    }

    .cpa-select .input-select .icon-down-open {
        right: 15px;
        left: unset;
    }

    .fatwrty-container .content-app h4 {
        padding-right: unset;
        padding-left: 40px;
        text-align: left;
    }

    .fatwrty-container .content-app p {
        text-align: left;
        padding-right: unset;
        padding-left: 40px;
    }

    .fatwrty-container .content-app h4::after {
        right: unset;
        left: 5px;
    }

    .fatwrty-container .features-container ul {
        text-align: left;
    }

    .fatwrty-container .download-container .imgs-download {
        padding-right: unset;
        padding-left: 40px;
        text-align: left;
    }

    .contentMap {
        background: #f8f9fa;
        border: 20px solid #f8f9fa;
        border-radius: 20px;
        margin-right: unset;
        margin-left: -20px;
    }

    .btn-menu:not(:disabled):not(.disabled) {
        background: #fafafc;
        // color: #6BE2B7;
        color: #282827;
        //box-shadow: 0px 0px 1px #676767;
        width: 100%;
        text-align: left;
    }

    .btn-subMenu:not(:disabled):not(.disabled) {
        background: #fafafc;
        color: #282827;
        //box-shadow: 0px 0px 1px #d1d1d1;
        width: 100%;
        text-align: left;
        padding-right: unset;
        // padding-left: 80px;
        margin-top: 6px;
    }

    .LogoDesc {
        background: #6be2b7;
        // height: 103px;
        margin-top: 50px;
        position: relative;
        right: unset;
        padding: 21px 12%;
        border-radius: 0px 10px 10px 0px;
        left: -7%;
        text-align: left;

        h2 {
            font-size: 1.25rem;
            color: #fff;
        }
    }

    .renderArea {
        box-shadow: 0px 0px 6px 1px #c5c5c5;
        padding: 20px;
        background: white;
        margin-top: 40px;
        margin-left: 20px;
        margin-right: unset;
    }

    @media only screen and (max-width: 768px) {
        .LogoDesc {
            background: #6be2b7;
            // height: 103px;
            margin-top: 50px;
            position: relative;
            left: unset;
            padding: 21px 12%;
            border-radius: 10px 10px 10px 10px;
            margin: auto;
            text-align: center;
            margin-top: 30px;

            h2 {
                // font-size: 2.25rem;
                color: #fff;
            }
        }
    }

    .img-container1 {
        background: #fff;
        border-radius: 50%;
        padding: 25px;
    }

    .btn-Menu1:not(:disabled):not(.disabled) {
        background: #fafafc;
        // color: #6BE2B7;
        color: #282827;
        //box-shadow: 0px 0px 1px #676767;
        width: 100%;
        text-align: left;
    }

    .btn-Menu1:not(:disabled):not(.disabled):hover {
        background: #f4f4f4;
        // color: #6BE2B7;
        color: #282827;
        //box-shadow: 0px 0px 1px #676767;
        width: 100%;
        text-align: left;
    }

    .btn-Menu1:not(:disabled):not(.disabled):focus {
        background: #6be2b7;
        color: white;
        //box-shadow: 0px 0px 1px #676767;
        width: 100%;
        text-align: left;
        margin-bottom: 20px;
    }

    #example-collapse-text {
        padding-right: unset;
        padding-left: 50px;
    }

    .deleteFileBtn {
        margin-left: unset !important;
        color: white !important;
        font-size: 1.5rem !important;
        position: relative !important;
        top: -1px !important;
        margin-right: 10px !important;
    }

    .icon-card .show_controller {
        text-align: right;
    }

    .Organize-container {
        .container {
            text-align: left;
            direction: ltr;
        }

        .collapse-head {
            padding: 25px 70px 25px 15px;
        }
    }

    .fqa-container {
        .collapse-head {
            padding: 15px 15px 10px 70px;
            text-align: left;
            P {
                font-size: 1.125rem;
            }
        }
        .collapse-content {
            P {
                font-size: 1rem;
            }
        }
    }

    .yearsContainer {
        border-left: unset;
        border-right: 1px;
        // border-right: 1px dashed;
        padding: 10px;

        button {
            border: 0px solid #6be2b7;
            border-radius: 20px;
            padding: 0px 12px 0px 12px;
            background: unset;
            //color: white;
            &.yearBtns.active {
                background-color: #6be2b7;
            }
        }

        button:focus {
            border: 0px solid #6be2b7;
            border-radius: 20px;
            padding: 0px 12px 0px 12px;
            background: #6be2b7;
            color: white;
        }
    }

    .customCard {
        i {
            left: unset;
            right: 10px;
        }
    }

    .customCard:hover {
        i {
            left: unset;
            right: 10px;
        }
    }

    .compareBtn {
        i {
            position: relative;
            right: 10px;
        }
    }
    @media (max-width: 425px) {
        .creditCardContainer {
            .compareBtn {
                i {
                    right: unset;
                    left: 0.5rem;
                }
            }
        }
    }

    .comapreAndRate-container {
        h1 {
            margin-right: 5rem;
        }
        .pageContent {
            .row {
                .productsList--directly {
                    top: 2.3rem;
                    left: 12rem;
                }
            }
        }
    }

    .sideMenu {
        background: white;
        padding: 10px;
        text-align: left;
        // height: 100%;
        box-shadow: 0px 0px 10px -2px #707070;
        border-radius: 10px;

        label {
            color: #6be2b7;
            width: 100%;
        }

        .btnContent {
            display: flex;
            // color: black;
            // i{
            //     position: relative;
            //     left: unset;
            //     right: -55px;
            // }
        }

        .btn-menu {
            padding: unset;
            width: 100%;
            // border: 1px solid black;
        }

        .btn-subMenu {
            color: #6be2b7;
            background: transparent !important;
        }

        .btn-subMenu:hover {
            color: black;
            background: transparent !important;
        }
    }

    #menuCollapseArea {
        padding-left: unset;
    }

    .worldDayPage-conatiner {
        text-align: center;

        .action-hover {
            text-align: left;
            h5 {
                text-align: left;
            }
        }

        .consumer-action-hover {
            text-align: left;
        }

        h4 {
            color: $cpa-dark-blue;
            margin: 25px 0;
            text-align: left;
        }

        .img-container {
            img {
                width: 100%;
                height: 250px;
                object-fit: fill;
            }
        }

        .header-img {
            width: 70%;
            margin-top: 2%;
        }

        .header-title {
            margin-top: 2%;
            color: $cpa-main;
            text-align: left;
        }

        .card-title {
            margin-top: 2%;
            text-align: left;
            color: $cpa-main;
        }

        .card-text {
            text-align: left;
            color: $cpa-gray;
            margin-bottom: 4%;
        }

        .sideMenu {
            background: white;
            padding: 10px;
            height: 100%;
            box-shadow: 0px 0px 10px -2px #707070;
            border-radius: 10px;
            label {
                // color: #6BE2B7;
                color: #215c80;
                width: 100%;
            }
            .sideMenu-label {
                color: #a6a8a7;
            }
            .btnContent {
                display: flex;
                // color: black;
                // i{
                //     position: relative;
                //     left: unset;
                //     right: -55px;
                // }
            }
            .btn-menu {
                padding: unset;
                width: 100%;
            }
            .btn-subMenu {
                color: #6be2b7;
                background: transparent !important;
            }
            .btn-subMenu:hover {
                color: black;
                background: transparent !important;
            }
        }
        .card-title,
        .card-text {
            margin-right: 2%;
        }

        .card-desc {
            p {
                margin-top: 33px;
                text-align: left;
                color: $cpa-defualt;
                font-size: 1.125rem;
                padding: 0;
                line-height: 42px;
            }
        }
    }

    @media (max-width: 425px) {
        .worldDayPage-conatiner {
            .vertical-menu {
                top: 3%;
                right: unset;
                left: 4rem;
            }
            .header-img {
                max-width: 100%;
                width: 100%;
            }
            .header-title {
                // margin-top: 18rem;
            }
        }
    }

    .guideUs-container .home-section .complains-card .complain-row h5 {
        text-align: left;
    }
    .guideUs-container
        .calendar-view
        .grid-view
        .month-card
        .day-card
        .date-card:before {
        right: unset;
        left: -8px;
    }
    .guideUs-container
        .calendar-view
        .grid-view
        .month-card
        .day-card:nth-child(even)
        .col-6:last-of-type
        .date-card:before {
        right: -8px;
        left: unset;
    }
    .guideUs-container
        .calendar-view
        .grid-view
        .month-card
        .day-card:nth-child(even)
        .col-6:first-of-type
        .text-card:before {
        right: unset;
        left: 11px;
        border-right: 10px solid #3fbf92;
        border-left: unset;
    }
    .guideUs-container
        .calendar-view
        .grid-view
        .month-card
        .day-card
        .text-card {
        &:before {
            border-left: 10px solid #3fbf92;
            border-right: unset;
            left: unset;
            right: 11px;
        }
        &.link {
            &:hover {
                &:before {
                    border-left: 10px solid #356a8b !important;
                    border-right: unset !important;
                }
            }
        }
    }

    .guideUs-container {
        .guideUs-intro {
            text-align: left;
        }
        .text-card {
            text-align: left !important;
            &.link {
                &:hover {
                    &:before {
                        border-right: 10px solid #356a8b !important;
                    }
                }
            }
        }
    }

    .working-on {
        text-align: left;
    }
    .head-weHearYou {
        .title {
            text-align: left;
        }
    }
    .guideUs-container .head-weHearYou .review-btn {
        text-align: right;
    }
    .home-section-head,
    .get-recived-row {
        text-align: left;
    }
    .guideUs-container
        .calendar-view
        .grid-view
        .month-card
        .day-card:nth-child(even)
        .date-card {
        text-align: right;
    }
    .guideUs-container
        .calendar-view
        .grid-view
        .month-card
        .day-card
        .date-card {
        text-align: left;
    }

    .creditCardContainer {
        text-align: left;
        h2 {
            text-align: left;
        }
        p {
            text-align: left;
        }
        .btn.submit-cpa-btn {
            width: 7rem;
        }

        .compareBtn {
            display: -webkit-box !important;
            .btnLabel {
                display: contents;
            }
        }
    }
    .newsCard-container .card-content .author-wrapper {
        text-align: left;
    }
    .guideUs-container .select-a-complain {
        float: right;
    }
    .LegalAdvocacy-container .nav-item .landing-card {
        min-height: 153px;
    }
}

html[lang="en"] {
    // .modal-header{
    //     right: unset;
    //     left:5px;
    // }

    .modal-header .close span:first-of-type {
        position: relative;
        top: -10px;
        left: -4px;
    }
    .members-modal,
    .compare-container {
        direction: ltr;
    }

    .quiz-section {
        direction: ltr;

        .modal-body {
            .quiz-content {
                text-align: left;

                .quiz-answers .form-check {
                    .check {
                        right: unset;
                        left: 0;
                    }

                    .ans-icon {
                        left: unset;
                        right: 5px;
                    }

                    label {
                        padding: 0 0 0 25px;
                    }
                }
            }
        }
    }

    .Authentication-container .form-input i[class*="icon"] {
        left: 8px;
        right: unset;
    }

    .Authentication-container {
        #registerFirstName,
        #registerLastName {
            direction: ltr;
        }
        .input-label {
            text-align: left;
        }
        .forgot-remimber-row {
            .forgot-password {
                text-align: center;
                color: #215c80;
            }
        }
    }

    .Authentication-container .form-input input {
        padding-left: 40px;
        padding-right: unset;
        text-align: left;
        // direction: ltr;
    }

    .Authentication-container .form-input select {
        direction: ltr;
    }

    .container-card .modal-body .share-control {
        direction: ltr;
        text-align: left;
    }

    .container-id-card .card-content {
        text-align: left;
    }

    .generalTips-container {
        + .compare-landing-container {
            text-align: left;
            margin-left: 8rem;
        }
        .pdfHeader {
            text-align: left;
        }
        .active {
            background-color: white;
            color: #6aceaa;
        }
        .generalTips {
            h1 {
                text-align: left;
            }
        }
        .videoTitle {
            margin-right: 66rem;
        }
        // h4{
        //     padding-right: 53rem;
        // }
        .generalInst {
            text-align: left;
            color: #6aceaa;
        }
        .card-content h4 {
            padding: 0;
        }
    }
    @media (max-width: 425px) {
        .comapreAndRate-container.landing-container {
            h1 {
                margin-right: 5rem;
            }
            .pageContent {
                .row {
                    .productsList--directly {
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}
