@import "src/styles/variables.scss";

.side-drawer {
    height: 100%;
    background: white;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    // width: 100%;

    overflow: auto;
    z-index: -9999;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    .side-drawer-header {
        display: flex;
        align-items: center;
        padding: 0 10%;
        font-size: 1rem;
        font-weight: 500;
        height: 85px;
        border-bottom: 1px solid #707070;
        i[class*="icon"] {
            color: $cpa-main;
            font-size: 1.5rem;
            cursor: pointer;
        }
        span {
            margin: -2rem;
            color: $cpa-dark-blue;
        }
    }
    .side-drawer-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 10%;

        height: auto;
        border-bottom: 1px solid #707070;
        .btn-drawer01 {
            width: 100%;
            font-size: 1rem;
            text-align: center;
            margin-bottom: 15px;
            background-color: $cpa-main;
        }
        .btn-drawer02 {
            width: 100%;
            font-size: 1rem;
            text-align: center;
            background-color: $cpa-dark-blue;
        }
    }
    .side-drawer-links {
        display: flex;
        flex-direction: column;
        padding: 15px 10%;
        a {
            margin: 10px 0;
            color: $cpa-defualt;
            font-size: 1rem;
            font-weight: bold;
            cursor: pointer;
            i[class*="icon"] {
                color: $cpa-main;
            }
        }
        .menu-icon {
            position: relative;
            &::before {
                position: absolute;
                top: 10px;
                right: 4px;
                z-index: 9;
                color: #42c194;
                vertical-align: middle;
                font-family: "fontello";
                transition: 0.75s all ease-in-out;
            }
        }
        .menu-about {
            &::before {
                content: "\E81A";
            }
        }
        .menu-prog {
            &::before {
                content: "\E817";
            }
        }
        .menu-media {
            &::before {
                content: "\E81B";
            }
        }
        .menu-contact {
            &::before {
                content: "\E819";
            }
        }
        h5 {
            color: $cpa-defualt;
            font-size: 1rem;
            position: relative;
            border: none;
            font-weight: bold;
            border-radius: 0;
            box-shadow: none !important;
            margin: 10px 0;
            padding: 0 28px;
            &::after {
                position: absolute;
                top: 5px;
                left: 0;
                vertical-align: middle;
                content: "\F004";
                font-family: "fontello";
                transition: 0.75s all ease-in-out;
            }
        }
        ul {
            list-style: none;
            padding: 0;
            li {
                display: block;
                border-bottom: 1px solid #eeeeee42;
                a {
                    font-weight: normal;
                    color: $cpa-gray;
                }
            }
        }
    }
}

.side-drawer.open {
    transform: translateX(0);
    z-index: 9999;
}

@media (max-width: 1024px) {
    .side-drawer {
        .side-drawer-buttons {
            .search-form {
                position: relative;
                top: 2rem;
                left: 8rem;
                .search-input {
                    background-color: #215c80;
                    width: 750%;
                    color: white;
                }
            }
        }
    }
}

@media (max-width: 426px) {
    .side-drawer {
        .side-drawer-buttons {
            .search-form {
                position: relative;
                top: 2rem;
                left: 6rem;
                .search-input {
                    background-color: #215c80;
                    width: 550%;
                    color: white;
                }
            }
        }
    }
}

@media (max-width: 300px) {
    .side-drawer {
        .side-drawer-header {
            span {
                margin: -20px;
            }
        }
    }
}
