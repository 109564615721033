@import "src/styles/variables.scss";
.articaleCard-container {
    margin-top: 35px;
    .main-header {
        h5 {
            color: $cpa-main;
            font-size: 1.25rem;
            margin-bottom: 15px;
        }
        .date {
            font-size: 0.75rem;
            i[class*="icon"] {
                margin-left: 5px;
                color: $cpa-dark-blue;
            }
        }
        .date_by {
            img {
                // width: 75px;
                width: 85px;
                height: 85px;
                border-radius: 50%;
                border: 5px solid #fff;
            }
        }
    }

    .main-img {
        margin-bottom: 56px;
        margin-top: 45px;
        height: 100%;
        img {
            width: 100%;
            max-height: 350px;
            object-fit: fill;
        }
        .carousel-item {
            transition: transform 0.3s ease-in-out,
                -webkit-transform 0.3s ease-in-out;
        }
        .info_graphic_body {
            .slick-prev::before {
                color: #42c194 !important;
            }
            .slick-arrow.slick-next::before {
                color: #42c194;
            }
        }
        .carousel-control-next {
            opacity: 1;
            color: #42c194;
        }
        .carousel-control-prev {
            opacity: 1;
            color: #42c194;
        }
    }
    .main-content {
        font-size: 1.125rem;
    }
}
.articale {
    .news-date {
        display: none;
    }
}
.news {
    .date_by {
        display: none;
    }
}
@media (max-width: 500px) {
    .articaleCard-container {
        .main-img {
            img {
                height: auto;
            }
        }
    }
}
