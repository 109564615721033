@import 'src/styles/variables.scss';
.scroll {
    opacity: 0.4;
    background-color: $cpa-main;
    width: 65px;
    height: 65px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    border-radius: 50%;
    border: none;
    display: none;
    
    &:hover {
      opacity: 1;
    }
    .arrow-up {
        color: white;
        position: absolute;
        top: 40%;
        left: 30%;
        margin-top: -9px;
        margin-left: -5px;
        transform: rotate(180deg);
        font-size: 1.75rem;
      }
  }
  .isScrolling{
      .scroll{
          display: block;
      }
  }
  