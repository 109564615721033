@import "src/styles/variables.scss";
.guideBook-container {
    margin-bottom: 55px;
    .iconImage {
        width: 2rem;
        height: 2rem;
    }
    .guideBook-content {
        margin-top: 40px;
        h1 {
            margin-top: 1rem;
            font-size: 1.5rem;
            margin-bottom: 48px;
        }
        .menuCol {
            background: white;
            padding: 15px;
        }
        .img-container1 {
            background: #fff;
            border-radius: 50%;
            padding: 25px;
            width: 150px;
            height: 150px;
            text-align: center;
        }
        .imgBiggerContainer {
            z-index: 1;
            padding: 0;
        }
        .active {
            background-color: transparent !important;
            .guide-card {
                box-shadow: 0 8px 9px #999 !important;
                background-color: $cpa-main !important;
                color: #fff !important;
                .inner {
                    border-color: #fff !important;
                }
            }
        }
        .guide-card {
            padding: 10px;
            margin: 15px;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 4px 5px #999;
            width: 100%;
            text-align: center;
            cursor: pointer;
            color: $cpa-defualt;
            // display: inline;
            &:hover {
                box-shadow: 0 8px 9px #999;
                h5 {
                    font-weight: bold;
                }
            }
            .inner {
                border: 1px solid $cpa-main;
                border-radius: 10px;
                padding: 10px;
            }
        }
        .pageContent-container {
            transition: 1s ease-in-out all;
            margin-top: 35px;
            h4 {
                color: $cpa-main;
                margin-bottom: 20px;
            }
            h5 {
                color: $cpa-gray;
                margin-bottom: 20px;
            }
            li {
                color: $cpa-defualt;
                margin-bottom: 10px;
            }
            img {
                width: 100%;
                height: 300px;
            }
        }
        label {
            color: #215c80;
            width: 100%;
            margin-top: 10px;
        }
        svg {
            margin-bottom: 8px;
            fill: #215c80 !important;
        }

        .share-link {
            padding-top: 0.4rem;
            cursor: pointer;
        }

        .share-control {
            position: absolute;
            margin-bottom: 0;
            background-color: white !important;
            box-shadow: 0 8px 9px #809696;
            border-radius: 10px;
            display: none;
            z-index: 999;
            top: 9rem;
            &.active {
                display: block;
            }
            svg {
                fill: white !important;
            }
        }
    }
}
.over-lay {
    background: #00000025;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999999;
    color: #fff;
    text-align: center;
    font-size: 3.25rem;
    padding: 20% 0;
}
.react-search-field-input::placeholder {
    color: #215c80;
}
.react-search-field {
    border: 1px solid #215c80 !important;
    border-radius: 5px !important;
    width: 100% !important;
    height: unset !important;
    overflow: hidden;
}

.dropdown .btn {
    width: 100%;
    text-align: unset;
    color: #6be2b7;
    background: #fafafc;
    border: 1px solid #707070;
    padding: 10px;
    border-radius: 10px;
}

.dropdown .btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    width: 100%;
    text-align: unset;
    color: #6be2b7;
    background: #fafafc;
    border: 1px solid #707070;
    padding: unset;
}

.LogoDesc {
    background: #6be2b7;
    // height: 103px;
    margin-top: 50px;
    position: relative;
    right: -12%;
    padding: 21px 12%;
    border-radius: 10px 0 0 10px;
    width: 39rem;
    h2 {
        font-size: 1.25rem;
        color: #fff;
    }
}

.PageTitle {
    h1 {
        color: #6be2b7;
        font-weight: bold;
    }
}

.pageContent {
    font-size: 1rem;
    margin: 20px;
}

.contentIcon {
    background: #6ae2b7;
    // padding: 30px;
    padding: 7px;
    text-align: center;
    color: white;
    border: 2px solid #69e2b7;
    border-radius: 0px 20px 20px 0px;
    height: fit-content;
    font-size: 3vw;
}

.contentRow {
    padding-top: 10px;
    margin-bottom: 1rem;
}

.contentMap {
    background: #fafafc;
    border: 20px solid #fafafc;
    border-radius: 20px 0px 20px 20px;
    // margin-right: -20px;
    margin-right: 0px;
    P {
        margin-bottom: 0;
    }
}

.btn-menu:not(:disabled):not(.disabled) {
    background: #fafafc;
    // color: #6BE2B7;
    color: #282827;
    //box-shadow: 0px 0px 1px #676767;
    width: 100%;
    text-align: right;
}

.renderArea {
    box-shadow: 0px 0px 6px 1px #c5c5c5;
    padding: 20px;
    background: white;
    margin-top: 40px;
    margin-right: 20px;
}

.pageHeader {
    margin-right: 10px !important;
    align-items: baseline;
    img {
        width: 100%;
        height: 100%;
    }
}

.btn-menu:not(:disabled):not(.disabled):hover {
    background: #f4f4f4;
    // color: #6BE2B7;
    color: #282827;
    //box-shadow: 0px 0px 1px #676767;
    width: 100%;
    text-align: right;
}

.btn-subMenu:not(:disabled):not(.disabled) {
    background: #fafafc;
    // color: #6BE2B7;
    color: #282827;
    //box-shadow: 0px 0px 1px #d1d1d1;
    width: 100%;
    text-align: right;
    //padding-right: 80px;
    margin-top: 6px;
}

.btn-subMenu:not(:disabled):not(.disabled):hover {
    background: #f4f4f4;
    // color: #6BE2B7;
    color: #282827;
    //box-shadow: 0px 0px 1px #d1d1d1;
    width: 100%;
    text-align: right;
    //padding-right: 80px;
    margin-top: 6px;
}

.btn-Menu1:not(:disabled):not(.disabled) {
    background: #fafafc;
    // color: #6BE2B7;
    color: #282827;
    //box-shadow: 0px 0px 1px #676767;
    width: 100%;
    text-align: right;
    margin-bottom: 20px;
}

.btn-Menu1:not(:disabled):not(.disabled):hover {
    background: #f4f4f4;
    // color: #6BE2B7;
    color: #282827;
    //box-shadow: 0px 0px 1px #676767;
    width: 100%;
    text-align: right;
    margin-bottom: 20px;
}

.btn-Menu1:not(:disabled):not(.disabled):focus {
    background: #6be2b7;
    // background: #282827;
    // color: white;
    color: white;
    //box-shadow: 0px 0px 1px #676767;
    width: 100%;
    text-align: right;
    margin-bottom: 20px;
}
.btn-Menu1:not(:disabled):not(.disabled).active {
    background: #6be2b7 !important;
    // background: #282827;
    // color: white;
    color: white !important;
    //box-shadow: 0px 0px 1px #676767;
    width: 100% !important;
    text-align: right !important;
    margin-bottom: 20px !important;
}

#example-collapse-text {
    // padding-right: 50px;
    // max-height: 400px;
    // overflow: auto;
}

@media only screen and (max-width: 768px) {
    .img-container1 {
        width: fit-content;
        margin: auto;
    }
    .LogoDesc {
        background: #6be2b7;
        width: 100%;
        // height: 103px;
        margin-top: 50px;
        position: relative;
        right: unset;
        padding: 21px 12%;
        border-radius: 10px 10px 10px 10px;
        margin: auto;
        text-align: center;
        margin-top: 30px;
        h2 {
            font-size: 1.25rem;
            color: #fff;
        }
    }
    .renderArea {
        margin-right: unset;
    }
    .PageTitle {
        text-align: center;
        h1 {
            color: #6be2b7;
            font-weight: bold;
        }
    }

    .pageHeader {
        margin-right: unset !important;
    }
}

@media only screen and (max-width: 426px) {
    .LogoDesc {
        margin-top: unset;
        padding: 0.7rem;
    }
}
