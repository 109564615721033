@import 'src/styles/variables.scss';
.takeScience-container{
    .container{
        margin-top: 25px;
    }
    h3{
        font-size: 1.5rem;
        color:$cpa-dark-blue;
        margin: 35px 0;
        i[class*="icon"]{
            margin-left: 5px;
            color:$cpa-main;
        }
    }
}