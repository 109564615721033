@import "src/styles/variables.scss";
.Authentication-container {
    #registerFirstName,
    #registerLastName {
        direction: rtl;
    }
    padding: 5%;
    text-align: center;
    font-size: 1rem;
    color: #bbbbbd;
    font-family: "cairo-Regular";
    #registerPhone {
        direction: ltr;
    }
    h4 {
        color: $cpa-dark-blue;
        font-size: 2.1875rem;
        font-weight: bold;
        line-height: 41px;
        margin-bottom: 8px;
    }
    p {
        color: #5e5e5e;
        font-size: 1.125rem;
        line-height: 41px;
    }
    .row {
        margin-top: 25px;
    }
    .error {
        color: red;
    }
    .input-label {
        font-size: 0.75rem;
        line-height: 19px;
        text-align: right;
        margin-bottom: 6px;
    }
    .form-input {
        color: #bbbbbd;
        position: relative;
        margin-bottom: 20px;
        i[class*="icon"] {
            position: absolute;
            top: -4px;
            right: -4px;
            color: #bbbbbd;
            font-size: 1.2rem;
        }
        .icon-mobile:before {
            content: "\E82E";
            padding-right: 3.25rem;
        }
        input,
        select {
            width: 100%;
            color: $cpa-gray;
            border-color: #e4e7f1;
            border-width: 0 0 1.5px 0;
            border-radius: 0;
            border-style: solid;
            text-align: right;
            padding: 0 40px 11px 0;
            // direction: rtl;
            color: #1a1a1a;
        }
        select {
            padding-right: unset;
        }
    }
    .password-input-container {
        position: relative;
        .show-password {
            position: absolute;
            top: 33px;
            left: 15px;
            .icon-eye {
                color: #215c80;
            }
        }
    }
    .react-phone-number-input__icon {
        width: 1.24em;
        height: 0.93em;
        border: 0;
        padding-left: 5px;
    }
    .react-phone-number-input__icon-image {
        position: relative;
        top: -7px;
    }
    .react-phone-number-input__country--native {
        position: relative;
        right: 35px;
    }
    .forgot-remimber-row {
        margin-top: 0;
        .forgot-password {
            text-align: right;
            i {
                font-size: 1.125rem;
                margin-left: 10px;
                color: $cpa-dark-blue !important;
            }
            span {
                font-size: 0.75rem;
                color: $cpa-dark-blue;
                line-height: 19px;
            }
        }
    }

    ::-webkit-input-placeholder {
        color: #bbbbbd;
    }
    .check-box {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .actions-controllers {
        .current-user {
            button {
                font-size: 1rem;
                border: none;
                line-height: 19px;
                color: #414141 !important;
                background: #fff !important;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .sumbit-cation {
            button {
                width: 215px;
            }
        }
    }
    /* Hide the browser's default checkbox */
    .check-box input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 10px;
        right: 30px;
        height: 15px;
        width: 15px;
        background-color: #fff;
        border: 2px solid #bbbbbd;
    }

    /* On mouse-over, add a grey background color */
    .check-box:hover input ~ .checkmark {
        color: #fff;
    }

    /* When the checkbox is checked, add a blue background */
    .check-box input:checked ~ .checkmark {
        color: #bbbbbd;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .check-box input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .check-box .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid #bbbbbd;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .font-fixed {
        font-size: 1rem;
    }

    button[type="submit"] {
        background-color: $cpa-main;
        color: #fff;
        border-color: $cpa-main;
        &:hover {
            background-color: #fff;
            color: $cpa-main;
        }
    }
    button[type="button"] {
        background-color: $cpa-dark-blue;
        color: #fff;
        border-color: $cpa-dark-blue;
        &:hover {
            background-color: #fff;
            color: $cpa-dark-blue;
        }
    }
    #registerCity {
        width: 40%;
    }
    .icon-menu-about {
        padding-right: 9rem;
    }
}
.current_user {
    .current-user-form {
        height: auto;
    }
    .new-user-form {
        height: 0;
        overflow: hidden;
    }
    .forgot-password-form-step1 {
        height: 0;
        overflow: hidden;
    }
    .forgot-password-form-step2 {
        height: 0;
        overflow: hidden;
    }
    .verify-form {
        height: 0;
        overflow: hidden;
    }
}
.new_user {
    .new-user-form {
        height: auto;
    }
    .current-user-form {
        height: 0;
        overflow: hidden;
    }
    .forgot-password-form-step1 {
        height: 0;
        overflow: hidden;
    }
    .forgot-password-form-step2 {
        height: 0;
        overflow: hidden;
    }
    .verify-form {
        height: 0;
        overflow: hidden;
    }
}
.forgot-password-step1 {
    .new-user-form {
        height: 0;
        overflow: hidden;
    }
    .current-user-form {
        height: 0;
        overflow: hidden;
    }
    .forgot-password-form-step1 {
        height: auto;
    }
    .forgot-password-form-step2 {
        height: 0;
        overflow: hidden;
    }
    .verify-form {
        height: 0;
        overflow: hidden;
    }
}
.forgot-password-step2 {
    .new-user-form {
        height: 0;
        overflow: hidden;
    }
    .current-user-form {
        height: 0;
        overflow: hidden;
    }
    .forgot-password-form-step1 {
        height: 0;
        overflow: hidden;
    }
    .forgot-password-form-step2 {
        height: auto;
    }
    .verify-form {
        height: 0;
        overflow: hidden;
    }
}
.verify {
    .new-user-form {
        height: 0;
        overflow: hidden;
    }
    .current-user-form {
        height: 0;
        overflow: hidden;
    }
    .forgot-password-form-step1 {
        height: 0;
        overflow: hidden;
    }
    .forgot-password-form-step2 {
        height: 0;
        overflow: hidden;
    }
    .verify-form {
        height: auto;
    }
}
