@import 'src/styles/variables.scss';
.impLinks-container{
        h4{
            color:$cpa-dark-blue;
            margin: 25px 0;
        }
        h5{
            text-align: right;
        }
        p{
            font-size: 1rem;
        }
        .end-one{
            color:#EA2E2E;
            font-size: 0.75rem;
            margin-bottom: 35px;
        }
        .card-file{
            background: #fff;
            border:1px solid #e2e2e2;
            border-radius: 5px;
            padding: 15px;
            margin-bottom: 25px;
            box-shadow: 0 1px 4px $cpa-gray;
            cursor: pointer;
            i[class*="icon"]{
                font-size: 2rem;
                color:$cpa-dark-blue;
            }
            h4{
                color:$cpa-main !important;
                font-size: 1rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 0 !important;
            }
            &:hover{
                h4{
                    font-weight: bold;
                }
                box-shadow: 0 8px 9px $cpa-gray;
            }
            
        }
    
}