@import "src/styles/variables.scss";
.carousel-item {
    background-color: #fff;
}
.details-row {
    .carousel-control-prev,
    .carousel-control-next {
        display: none;
    }
    .carousel-fade {
        transform: unset;
    }
}
.details-slider-img {
    height: auto;

    img {
        height: 215px;
        border-radius: 5px;
        max-width: 100%;
        margin: 0 auto;
    }
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
}
.details-slider-caption {
    padding-top: 0;
    .carousel-caption {
        position: unset;
        text-align: right;
        top: 0;
        padding: 0 15px 30px;
        left: 0;
        right: 0;
        .caption-content {
            h3 {
                font-size: 1.625rem;
                margin-bottom: 1rem;
                color: $cpa-dark-blue;
            }
            .content-description {
                line-height: 2;
                height: 118px;
                overflow: hidden;
            }
            p {
                font-size: 1rem;
                color: $cpa-defualt;
                line-height: 1.5;
            }
            p.date-news {
                font-size: 0.75rem;
                i[class*="icon"] {
                    color: $cpa-dark-blue;
                    margin-left: 5px;
                    font-size: 1.2rem;
                }
            }
        }

        .btn-outline-success {
            margin: 10px 0;
            border: 2px solid $cpa-main !important;
            color: $cpa-main !important;
            line-height: 1.5;
            font-size: 1rem;
            :hover {
                background-color: $cpa-main;
                color: #fff !important;
            }
        }
    }
}

.home-section {
    .carousel-indicators {
        text-align: right;
        // right: -20%;
        left: unset;
        bottom: -60px;
        li {
            height: 10px;
            width: 10px;
            border: 1px solid #000;
            background-color: #fff;
            border-radius: 50%;
        }
        .active {
            border: 2px solid #75d1b1;
            background-color: #75d1b1;
            margin-left: 10px;
        }
        @media (max-width: 768px) {
            text-align: center;
            bottom: -15px;
            width: 100%;
            margin: 0;
        }
    }
}
.carousel-control-next {
    // right: -3rem !important;
}
.carousel-control-prev {
    // left: -3rem !important;
    // z-index: 111111111111 !important;
}
.news-position {
    position: relative;
    .carousel-control-prev-icon {
        position: absolute;
        top: 45%;
        left: -2rem;
        cursor: pointer;
        background-image: none !important;
        color: $cpa-main;
        -webkit-text-stroke: 1px $cpa-main;
        -o-text-stroke: 1px $cpa-main;
        -ms-text-stroke: 1px $cpa-main;
        -moz-text-stroke: 1px $cpa-main;
    }
    .carousel-control-next-icon {
        position: absolute;
        background-image: none !important;
        top: 45%;
        right: -2rem;
        cursor: pointer;
        color: $cpa-main;
        -webkit-text-stroke: 1px $cpa-main;
        -o-text-stroke: 1px $cpa-main;
        -ms-text-stroke: 1px $cpa-main;
        -moz-text-stroke: 1px $cpa-main;
    }
}

/* /////////////////////////////////////////////////////////

//////// Media Query

////////////////////////////////////////////////////////
 */

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
    .home-section .carousel-indicators {
        width: 100%;
        bottom: 0;
        margin: 0;
        padding: 0 10px;
    }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
    .home-section .carousel-indicators {
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 0 10px;
    }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    .home-section .carousel-indicators {
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 0 10px;
    }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .details-slider-caption .carousel-caption .caption-content h3 {
        font-size: 1.5rem;
        margin: 1rem 0;
    }
    .home-section .carousel-indicators {
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 0 10px;
    }
}
