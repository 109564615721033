@import 'src/styles/variables.scss';
    .collapse-head{
        color: $cpa-main;
        font-size: 1.375rem;
        line-height: 30px;
        border-radius: 0.75rem;

    }
    .collapse-content{
        font-size: 1.125rem;
        padding: 0 25px;
    }
