.CookieClass{
    background-color: #42C194;
    position: fixed;
    z-index: 999;
    bottom: 0rem;
    // top: 85%;
    // height: 7rem;
    width: 100%;
    padding: 1rem 0rem 1rem 0rem;
    .btn{
        background-color: white;
        color: #42C194;
        float: left;
        margin-left: 1rem;
        // margin-top: -2rem;
    }
    p{
        color: white;
        font-size: 1rem;
        text-align: right;
        padding-right: 1rem;
    }
}