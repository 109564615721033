@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?57404488');
  src: url('../font/fontello.eot?57404488#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?57404488') format('woff2'),
       url('../font/fontello.woff?57404488') format('woff'),
       url('../font/fontello.ttf?57404488') format('truetype'),
       url('../font/fontello.svg?57404488#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?57404488#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-search-1:before { content: '\e800'; } /* '' */
.icon-right-open:before { content: '\e801'; } /* '' */
.icon-tweets:before { content: '\e802'; } /* '' */
.icon-users:before { content: '\e803'; } /* '' */
.icon-special:before { content: '\e804'; } /* '' */
.icon-linkedin:before { content: '\e805'; } /* '' */
.icon-company:before { content: '\e806'; } /* '' */
.icon-board:before { content: '\e807'; } /* '' */
.icon-privacy-ico:before { content: '\e808'; } /* '' */
.icon-systems:before { content: '\e809'; } /* '' */
.icon-compalins:before { content: '\e80a'; } /* '' */
.icon-studies:before { content: '\e80b'; } /* '' */
.icon-right-arrow:before { content: '\e80c'; } /* '' */
.icon-left-arrow:before { content: '\e80d'; } /* '' */
.icon-twitter:before { content: '\e80e'; } /* '' */
.icon-projector:before { content: '\e80f'; } /* '' */
.icon-calendar:before { content: '\e810'; } /* '' */
.icon-consumer:before { content: '\e811'; } /* '' */
.icon-service:before { content: '\e812'; } /* '' */
.icon-sub:before { content: '\e813'; } /* '' */
.icon-phone:before { content: '\e814'; } /* '' */
.icon-down-dir:before { content: '\e815'; } /* '' */
.icon-close:before { content: '\e816'; } /* '' */
.icon-menu-prog:before { content: '\e817'; } /* '' */
.icon-align-right:before { content: '\e818'; } /* '' */
.icon-menu-contact:before { content: '\e819'; } /* '' */
.icon-menu-about:before { content: '\e81a'; } /* '' */
.icon-menu-media:before { content: '\e81b'; } /* '' */
.icon-share:before { content: '\e81c'; } /* '' */
.icon-user:before { content: '\e81d'; } /* '' */
.icon-search:before { content: '\e81e'; } /* '' */
.icon-data:before { content: '\e81f'; } /* '' */
.icon-videos:before { content: '\e820'; } /* '' */
.icon-about-us:before { content: '\e821'; } /* '' */
.icon-l-nash2a:before { content: '\e822'; } /* '' */
.icon-resal:before { content: '\e823'; } /* '' */
.icon-save-img:before { content: '\e824'; } /* '' */
.icon-fication:before { content: '\e825'; } /* '' */
.icon-drive:before { content: '\e826'; } /* '' */
.icon-job:before { content: '\e827'; } /* '' */
.icon-location:before { content: '\e828'; } /* '' */
.icon-clock:before { content: '\e829'; } /* '' */
.icon-Observation:before { content: '\e82a'; } /* '' */
.icon-spreading:before { content: '\e82b'; } /* '' */
.icon-lock:before { content: '\e82c'; } /* '' */
.icon-mail:before { content: '\e82d'; } /* '' */
.icon-mobile:before { content: '\e82e'; } /* '' */
.icon-picture:before { content: '\e82f'; } /* '' */
.icon-link:before { content: '\e830'; } /* '' */
.icon-electronic-fraud:before { content: '\e831'; } /* '' */
.icon-fake-offers:before { content: '\e832'; } /* '' */
.icon-financial-fraud:before { content: '\e833'; } /* '' */
.icon-investing-money:before { content: '\e834'; } /* '' */
.icon-pyramid-fraud:before { content: '\e835'; } /* '' */
.icon-phone-1:before { content: '\e836'; } /* '' */
.icon-briefcase:before { content: '\e837'; } /* '' */
.icon-heart-empty:before { content: '\e838'; } /* '' */
.icon-resize-small:before { content: '\e839'; } /* '' */
.icon-resize-full:before { content: '\e83a'; } /* '' */
.icon-logout:before { content: '\e83b'; } /* '' */
.icon-up-dir:before { content: '\e83c'; } /* '' */
.icon-user-1:before { content: '\e83d'; } /* '' */
.icon-shape-18:before { content: '\e84b'; } /* '' */
.icon-down-open:before { content: '\f004'; } /* '' */
.icon-download:before { content: '\f02e'; } /* '' */
.icon-twitter-circled:before { content: '\f057'; } /* '' */
.icon-certificate:before { content: '\f0a3'; } /* '' */
.icon-exchange:before { content: '\f0ec'; } /* '' */
.icon-angle-double-left:before { content: '\f100'; } /* '' */
.icon-mobile-1:before { content: '\f10b'; } /* '' */
.icon-sort-number-down:before { content: '\f163'; } /* '' */
.icon-youtube:before { content: '\f16a'; } /* '' */
.icon-left:before { content: '\f177'; } /* '' */
.icon-female:before { content: '\f182'; } /* '' */
.icon-male:before { content: '\f183'; } /* '' */
.icon-graduation-cap:before { content: '\f19d'; } /* '' */
.icon-fax:before { content: '\f1ac'; } /* '' */
.icon-transgender:before { content: '\f224'; } /* '' */
.icon-map-o:before { content: '\f278'; } /* '' */
.icon-shopping-bag:before { content: '\f290'; } /* '' */
.icon-snapchat:before { content: '\f2ac'; } /* '' */
.icon-address-book-o:before { content: '\f2ba'; } /* '' */
.icon-telegram:before { content: '\f2c6'; } /* '' */
.icon-twitter-1:before { content: '\f309'; } /* '' */
.icon-facebook:before { content: '\f30c'; } /* '' */
.icon-instagram:before { content: '\f32d'; } /* '' */