@import 'src/styles/variables.scss';

.alljobs-container {
    .title {
        color: $cpa-main;
        font-weight: 400;
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 2%;
    }
    // .container-card{
    //     height: 100%;
    // }
    .job-container {
        .job-card-closed {
            margin-bottom: 30px;
            background-color: white;
            padding: 25px 25px 25px 25px;

            i {
                margin-left: 10px;
            }

            h5 {
                color: $cpa-dark-blue;

                i {
                    color: $cpa-main;
                    cursor: pointer;
                }
            }

            p {
                color: $cpa-gray;
                padding-bottom: 0;
            }
        }

        .job-card-opened {
            margin-bottom: 30px;
            background-color: white;
            padding: 25px 25px 25px 25px;

            h5 {
                color: $cpa-main;
            }

            p {
                color: $cpa-gray;
                padding-bottom: 0;
            }

            button {
                background-color: white;
                color: $cpa-black;
                border: 1.5px solid $cpa-black;
                outline: none;
                cursor: pointer;
                border-radius: 5px;
            }

            .icon-resize-small {
                font-size: 1.125rem;
                cursor: pointer;
            }
        }
    }
}

.nojobs-container {
    background-color: white;
    text-align: center;
    padding: 30px;

    .icon-special {
        font-size: 40px;
    }

    h5 {
        color: $cpa-main;
        margin-top: 20px;
    }

    p {
        color: $cpa-gray;
    }

    button {
        height: 50px;
        width: 150px;
        background-color: #2f6688;
        color: white;
        outline: none;
        border: none;
        margin-bottom: 50px;
        margin-top: 15px;
        cursor: pointer;

        i {
            margin-right: 8px;
        }
    }
}

.singlejob-container {
    background-color: white;

    .job-card-opened {
        margin-bottom: 30px;
        background-color: white;
        padding: 25px 25px 25px 25px;
        margin-top: 50px;

        h5 {
            color: $cpa-main;
        }

        p {
            color: $cpa-gray;
            padding-bottom: 0;
        }

        button {
            background-color: white;
            color: $cpa-black;
            border: 1.5px solid $cpa-black;
            outline: none;
            cursor: pointer;
            border-radius: 5px;
        }

        .icon-resize-small {
            font-size: 1.125rem;
            cursor: pointer;
        }
    }

    .job-form {
        h5 {
            color: $cpa-main;
        }

        button {
            height: 50px;
            width: 150px;
            background-color: #2f6688;
            color: white;
            outline: none;
            border: none;
            margin-bottom: 50px;
            margin-top: 15px;
            cursor: pointer;

            i {
                margin-right: 8px;
            }
        }
    }
}

.algin-left {
    text-align: left;
}

.applyJob-content {
    width: 100%;
    margin-top: 3%;

    .nav-link.active {
        background-color: $cpa-main !important;
    }
}

.moving-tab {
    display: none;
}

.card-header,
.card-footer {
    background-color: white !important;
}

.form-check-label {
    margin-top: 0;
    margin-right: 4%;
}

.form-check-input {
    margin-top: 2% !important;
    margin-left: 25px !important;
}

.week-days {
    margin-right: 1%;
    margin-left: 1%;
}

.forms-badges {
    font-size: 20px !important;
    margin-left: 1%;
    margin-bottom: 2%;
    cursor: pointer;
}

.card-footer> :first-child {
    float: left !important;
}

.card-footer> :nth-child(2) {
    float: right !important;
}
