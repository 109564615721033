@import 'src/styles/variables.scss';
.img-header{
    position: relative;
    height: 35vh;
    line-height: 40px;
    overflow: hidden;
    .img-container{
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 0;
        position: absolute;
        img{
            width: 100%;
        }
        .overlay{
            position: absolute;
            background-color: #000;
            opacity: 0.3;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }
    }
    
    .img-header-content{
        width: 50%;
        text-align: center;
        position: relative;
        z-index: 1;
        margin: auto;
        top: 48%;
        h3{
            font-size: 2rem;
            color: #fff;
        }
        p{
            font-size: 1rem;
            height: 80px;
            line-height: 40px;
            overflow: hidden;
            color: #fff;
        }
    }
}
