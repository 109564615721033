.technical {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        display: block;
        width: 28rem;
    }
}
@media (max-width: 400px) {
    .technical {
        img {
            width: 22rem;
        }
    }
}
