.compare-page-container {
    .compareContent {
        padding: 0 15%;
    }
}
.comapreAndRate-container.landing-container {
    .share-link {
        cursor: pointer;
    }
    .share-control {
        background-color: transparent;
    }

    .pageContent {
        .row {
            .productsList--directly {
                top: 2.3rem;
                // right: 12rem;
            }
        }
    }
    table td,
    table th {
        max-width: 460px;
        border-right: 15px solid #f8f9fa;
        border-left: 15px solid #f8f9fa;
        border-top: 0;
        border-bottom: 0;
        padding: 18px 7.5px;
        vertical-align: unset;
        img {
            height: 166px;
            margin: 25px auto;
            max-width: 263px;
        }
    }
    table tr:nth-child(even) {
        td:not(:first-of-type) {
            background: #fff;
        }
    }
    table tr:nth-child(odd) {
        td:not(:first-of-type) {
            background: #f5f5f5;
        }
    }
    table tr {
        th:not(:first-of-type) {
            background: #fff;
            border-radius: 50px 50px 0 0;
            overflow: hidden;
        }
        th:first-of-type {
            vertical-align: middle;
        }
        td:first-of-type {
            font-weight: bold;
            font-size: 1.125rem;
        }
    }
    .comparingBar {
        .card {
            width: 18rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            .btn.submit-cpa-btn {
                width: 1px;
                display: flex;
                background-color: #6be2b7;
                color: white;
                justify-content: center;
                padding: 6px 18px;
            }
            .btn.submit-cpa-btn:hover {
                background-color: white;
                color: #42c194;
            }
        }
    }
    .comparingBar {
        position: fixed;
        top: 88%;
        z-index: 999;
        margin-right: 0rem;
        align-items: center;
    }
}
@media (max-width: 425px) {
    .compare-page-container.comapreAndRate-container.landing-container {
        table {
            th {
                border-right: 1px solid #f8f9fa;
                border-left: 1px solid #f8f9fa;
            }
            tr {
                td {
                    font-size: 0.7rem;
                    border-right: 1px solid #f8f9fa;
                    border-left: 1px solid #f8f9fa;
                }
            }
        }
        .compareContent {
            .comparingObjects {
                margin-right: 0;
                margin-left: 0;
                .pageTitle {
                    text-align: center;
                }
            }

            .filtersContainer {
                // display: unset;
                display: flex;
                overflow: auto;
                span {
                    label {
                        // width: 45%;
                        width: max-content;
                        margin: 0 0 15px 5px;
                    }
                }
            }
            padding: 0 0;
            h3 {
                font-size: 1rem !important;
            }
            .addToComparision {
                border-right: unset;
                border-left: unset;
                padding: 0 0;
                button {
                    padding: 5px 10px 5px 10px;
                }
            }
            .removeFromComparison {
                text-align: center;
                button {
                    padding: 5px 10px 5px 10px;
                }
            }
        }
    }

    .comapreAndRate-container.landing-container {
        .pageContent {
            .row {
                .productsList--directly {
                    top: 0;
                    right: 0;
                }
            }
        }
        .compareContent {
            .comparingObjects {
                display: flex;
                flex-wrap: wrap;
                margin-right: -100px;
                margin-left: -75px;
            }
        }
        .comparingBar {
            position: fixed;
            top: 75%;
            z-index: 999;
            margin-right: 0rem;
            align-items: center;
        }
        table th img {
            height: 70px;
            margin: 25px auto;
            max-width: 263px;
        }
    }
}
