@import 'src/styles/variables.scss';
.fatwrty-container{
    iframe{
        margin-top: 25px;
    }
    .content-app{
        padding: 45px 0;
        text-align: right;
        h4{
            font-size:1.5rem;
            color:$cpa-dark-blue;
            position: relative;
            padding-right: 40px;
            margin-bottom: 20px;
            &::after{
                position: absolute;
                content: "";
                top:5px;
                right: 5px;
                width:15px;
                height: 15px;
                background: $cpa-main;
                border-radius: 50%;
            }
        }
        p{
            color:$cpa-defualt;
            font-size: 1rem;
            padding-right: 40px;

        }
    }
    .features-container{
        ul{
            list-style: none;
            font-size: 1rem;
            padding-right: 0px;
            li{
                margin-bottom: 10px;
                i[class*="icon"]{
                    
                    color:$cpa-main;
                    margin-left: 15px;
                    font-size: 1.25rem;
                }
            }
            
        }
    }
    .download-container{
        padding-top: 0px;
        .imgs-download{
            padding-right: 40px;
            .appstore{
                width: 175px;
                margin: 0 7.5px;
                
            }
            .googleplay{
                width: 220px;
                position: relative;
                right: -15px;
                margin: 0 7.5px;
            }
        }
    }
    
}